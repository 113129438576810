import { Segment } from '../services/vipHall/types';
import { ICartItem } from './insurance';

interface IBookingService {
  getState: () => IBookingStore,
}

interface IBookingStore {
  statuses: IStatus[],
}

interface IReservationStore {
  progress: number;
  bookingId: null;
  services: null;
  errors: string[];
  status: string,
  statuses: string[],
  startDate: string,
  cart: {
    action?: {
      payload?: string
    }
  },
  cartItemByAnalogRooms: {
    [cartItemId: number | string]: { Guid: string }[],
  },
  errorMessages: string[],
}

interface IData {
  hotel: {
    Name: string,
  }
  Routes: {
    Segments: Segment[]
  } [],
  StationDepart: string,
  StationArrive: string,
  StartPlace: { Address: string },
  EndPlace: { Address: string },
}

type ResType = string | IResultGotten;

interface IResultGotten {
  BookId: string,
  ErrorMessages: string,
  OrderId?: string,
}

interface IError {
  status: number,
  message: string,
}

interface ICart {
  Id: number,
  BookId: null | number,
  Approve: any[],
  IsOffline: boolean,
  Items: ICartItem[],
  MiceCartLink: null | string,
  Name: string,
  ServerTime: string,
  ShowPurchaseButton: boolean,
  Status: string,
  UserAnalytics: number[],
}

interface IEmployee {
  Employee: {
    Surname: string;
    Name: string;
  }
}

interface IService {
  ServiceType: string;
  Data: string;
  Employees: IEmployee[];
}

interface IRoute {
  Segments: {
    DepartureCity: string;
    ArrivalCity: string;
  }[]
}

interface IStatus {
  AdditionalPrice: number,
  AnalogRooms: any[],
  BookingStep: string,
  CartItemId: number,
  Changes: string,
  Error: any | null,
  NewPrice: number,
  PriceOrigin: number,
}

enum CHANGES {
  NoChanges = 'NoChanges',
  PriceChanges = 'PriceChanges',
  NotEnoughPlaces = 'NotEnoughPlaces',
  SoldOut = 'SoldOut',
}

enum BOOKINGSTEP {
  Unknown = 'Unknown',
  Metadata = 'Metadata',
  PrepareToReserve = 'PrepareToReserve',
  Reservation = 'Reservation',
  Reserved = 'Reserved',
  ReservationError = 'ReservationError',
  PrepareToBooking = 'PrepareToBooking',
  Booking = 'Booking',
  Booked = 'Booked',
  BookingError = 'BookingError',
  ReadyToBook = 'ReadyToBook',
}

export { BOOKINGSTEP, CHANGES };

export type {
  IBookingService,
  IReservationStore,
  IService,
  IRoute,
  IData,
  ResType,
  IError,
  ICart,
};
