// @ts-nocheck
import React, { Component } from 'react';
import { Button, Checkbox, IconButton, Select, StyledWrapper, Text, PROPS } from 'new-ui';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { getText } from '../../../../../../i18n';

import ChildrenPassangers from '../ChildrenPassangers';

import { AirlineSearchRoute } from '../AirlineSearchRoute';

import { MainAnalytic } from '../../../../../bi/utils/analytics';
import { isSmartAgent } from '../../../../../bi/utils/env';

import { TYPE_AIR_SEARCH } from '../../const';

import styles from './styles.module.css';

const LABELS = {
  ADD_FLIGHT: getText('components:menu.air.main.addFlight'),
  SEARCH: getText('common:search'),
  TRAVELLERS: getText('components:menu.air.main.passengers'),
  CLASS: getText('components:menu.air.main.class'),
  DIRECT: getText('components:menu.air.main.direct'),
  COMPLEX: getText('components:menu.air.main.complex'),
  CHECKER: {
    ADULT_TITLE: getText('components:menu.air.checker.adults'),
    ADULT_SUBTITLE: getText('components:menu.air.checker.subAdults'),
    CHILDREN_TITLE: getText('components:menu.air.checker.child'),
    CHILDREN_SUBTITLE: getText('components:menu.air.checker.subChild'),
    INFANT_TITLE: getText('components:menu.air.checker.infant'),
    INFANT_SUBTITLE: getText('components:menu.air.checker.subInfant'),
    INFANT_INFO: getText('components:menu.air.checker.infantInfo'),
  },
};

const FIELDS = {
  DIRECT: 'direct',
  COMPLEX: 'complex',
};

class AirlineMainMenu extends Component {
  static propTypes = {
    airlineService: PropTypes.object.isRequired,
    handleChangeAdult: PropTypes.func.isRequired,
    handleChangeChild: PropTypes.func.isRequired,
    handleChangeFlightClass: PropTypes.func.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired,
    qaAttrs: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
  };

  static defaultProps = {
    qaAttrs: null,
  };

  state = {
    travellersOptions: this.props.airlineService.travellersOptions,
    classOptions: this.props.airlineService.classOptions,
    ...this.props.airlineService.getSearch(),
    colorText: isSmartAgent && this.props.featureFlagsService.getShowElementForSmartagent() ? 'white' : 'default',
  };

  componentDidMount() {
    const { airlineService } = this.props;

    this.unsubscribeFn = airlineService.subscribeSearch(this.updateState);
  }

  componentWillUnmount() {
    this.unsubscribeFn();
  }

  updateState = (state) => {
    this.setState({
      ...state,
    });
  };

  handleChangeDirectCheckbox = value => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.AIRLINE.SEARCH_AIR_TRANSPLANTS_CHOSEN);

    return this.props.airlineService.setSearchValue(FIELDS.DIRECT, value);
  };

  handleChangeComplexCheckbox = value => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.AIRLINE.SEARCH_AIR_DIFFICULT_ROUTE_CHOSEN);

    return this.props.airlineService.setSearchValue(FIELDS.COMPLEX, value);
  };

  renderLabel = (label, style = '') =>
    <Text
      type='NORMAL_14'
      className={ style }
      color={ this.state.colorText }
    >
      { label }
    </Text>;

  renderAddButton = () => (
    <div className={ styles.add_button }>
      <IconButton
        iconType={ PROPS.ICON.TYPES.PLUS.ROUND }
        onClick={ () => this.props.airlineService.addSearchRoute() }
        qaAttr={ this.props.qaAttrs?.addRoute || '' }
        alternativeDesign={ isSmartAgent }
      >
        { this.renderLabel(LABELS.ADD_FLIGHT, styles.label_add) }
      </IconButton>
    </div>
  );

  renderPassangers = () => {
    const {
      travellersOptions,
      travellers,
    } = this.state;
    const {
      handleChangeAdult,
      handleChangeChild,
      featureFlagsService: {
        getNewAirlineSearch,
        getShowElementForSmartagent,
      },
      airlineService,
      qaAttrs,
    } = this.props;

    if (getNewAirlineSearch() && isSmartAgent) {
      const colorText = isSmartAgent && getShowElementForSmartagent() ? 'white' : 'default';

      return (
        <ChildrenPassangers
          airlineService={ airlineService }
          handleChangeChild={ handleChangeChild }
          handleChangeAdult={ handleChangeAdult }
          colorText={ colorText }
          type={ TYPE_AIR_SEARCH.MAIN }
        />
      );
    }

    return (
      <>
        { this.renderLabel(LABELS.TRAVELLERS, styles.label) }
        <div className={ styles.passengers }>
          <Select
            theme={ PROPS.SELECT.THEMES.DEFAULT_SMALL }
            items={ travellersOptions }
            value={ travellers }
            onChange={ handleChangeAdult }
            qaAttr={ qaAttrs?.passengers || '' }
          />
        </div>
      </>
    );
  };

  render() {
    const {
      routes,
      isDirect,
      isComplex,
      isValid,
      flightClass,
      classOptions,
    } = this.state;
    const {
      airlineService,
      onSearch,
      handleChangeFlightClass,
      qaAttrs,
      featureFlagsService: {
        getShowElementForSmartagent,
      },
    } = this.props;

    const optionsWrapper = clsx(styles.options, {
      [styles.options_sa]: isSmartAgent && getShowElementForSmartagent(),
    });

    const addButtonHtml = isComplex && this.renderAddButton();
    const routesClass = [styles.row_route];

    if (isComplex) {
      routesClass.push(styles.complex);
    }

    const routesHtml = routes.map((route, ind) => (
      <AirlineSearchRoute
        key={ `airline__row__${ind}` }
        ind={ ind }
        route={ route }
        isComplex={ isComplex }
        airlineService={ airlineService }
        qaAttrs={ qaAttrs }
      />
    ));

    return (
      <StyledWrapper className={ styles.wrapper }>
        <div className={ routesClass.join(' ') }>
          { routesHtml }
          <div className={ styles.action }>
            { addButtonHtml }
            <Button
              className={ styles.search_button }
              type={ PROPS.BUTTON.TYPES.LARGE }
              disabled={ !isValid }
              onClick={ onSearch }
              qaAttr={ qaAttrs?.searchButton || '' }
            >
              { LABELS.SEARCH }
            </Button>
          </div>
        </div>
        <div className={ optionsWrapper }>
          <div className={ styles.part_options }>
            { this.renderPassangers() }
            {this.renderLabel(LABELS.CLASS, styles.label)}
            <div className={ styles.fly_class }>
              <Select
                theme={ PROPS.SELECT.THEMES.DEFAULT_SMALL }
                items={ classOptions }
                value={ flightClass }
                onChange={ handleChangeFlightClass }
                qaAttr={ qaAttrs?.flyClass || '' }
              />
            </div>
          </div>
          <div className={ styles.part_options }>
            <div className={ styles.direct }>
              <Checkbox
                value={ isDirect }
                onChange={ this.handleChangeDirectCheckbox }
                qaAttr={ qaAttrs?.direct || '' }
                isWhite={ isSmartAgent && getShowElementForSmartagent() }
              >
                { this.renderLabel(LABELS.DIRECT) }
              </Checkbox>
            </div>
            <Checkbox
              value={ isComplex }
              onChange={ this.handleChangeComplexCheckbox }
              qaAttr={ qaAttrs?.complex || '' }
              isWhite={ isSmartAgent && getShowElementForSmartagent() }
            >
              { this.renderLabel(LABELS.COMPLEX) }
            </Checkbox>
          </div>
        </div>
      </StyledWrapper>
    );
  }
}

export { AirlineMainMenu };
