import React, { ComponentType, ReactNode, useEffect, useState } from 'react';
import { Icon, Price, Text } from 'new-ui';

import { getText } from '../../../i18n';

import { isSmartAgent } from '../../bi/utils/env';

import SERVICETYPE, { SERVICES_ICON } from '../../bi/constants/serviceType';
import { TRIPSTATUS, VERSIONSTATUS } from '../../bi/constants/trips';

import AdditionalFee from './components/AdditionalFee/AdditionalFee';

import { ExternalLink } from '../ExternalLink';

import styles from './styles/itemLayout.module.css';

const FIELD = {
  NOTE_ITEM_ID: 'NoteItemId',
  CART_ITEM_ID: 'CartItemId',
};

const LABELS = {
  ADD: getText('cart:addFee:itemLayout:addFee'),
  RUB: (price: string | number) => getText('cart:addFee:itemLayout:price', { price }),
  ADDITIONAL_SERVICES: {
    ADDITIONAL_REFERR_INFO: getText('trip:additionalServices.additionalReferrlInfo'),
    ADDITIONAL_REFERR_LINK: getText('trip:additionalServices.additionalReferrlLink'),
    ADDITIONAL_REFERR_CONT: getText('trip:additionalServices.additionalReferrlCont'),
  },
};

interface ItemLayoutProps {
  price?: number | any,
  hideActions?: boolean,
  serviceType: string,
  children: ComponentType | any,
  renderOption?: ComponentType | any,
  renderAdditionalPriceText?: ReactNode;
  html?: ComponentType | any,
  absoluteHtml?: ComponentType | any,
  employeesHtml?: ComponentType | any,
  isSeat?: boolean,
  AdditionalServiceType?: number,
  qaAttrPrice?: string,
  qaAttrIcon?: string,
  renderCustomPrice?: any,
  itemId?: number,
  feeSmartagent?: number,
  setAdditionalFeeSmartagent?(data: { AgentFee: string, TripItemId?: number, CartItemId?: number }): void,
  isAdditionalFee?: boolean,
  isOnlyReadingAdditionalFee?: boolean,
  isTrip?: boolean,
  referralLink?: string | null,
  status?: string,
  versionStatus?: number | null,
  isNoteItem?: boolean,
  disabled?: boolean,
}

const ItemLayout = ({
  serviceType,
  children,
  employeesHtml = null,
  price = null,
  renderOption = null,
  renderAdditionalPriceText = null,
  html = null,
  absoluteHtml = null,
  hideActions = false,
  isSeat = false,
  AdditionalServiceType = 0,
  qaAttrPrice = '',
  qaAttrIcon = '',
  renderCustomPrice = null,
  itemId = 0,
  feeSmartagent = 0,
  setAdditionalFeeSmartagent = () => {},
  isAdditionalFee = false,
  isOnlyReadingAdditionalFee = false,
  isTrip = false,
  referralLink = null,
  status = '',
  versionStatus = null,
  isNoteItem = false,
  disabled = false,
}: ItemLayoutProps) => {
  const [fee, setFee] = useState(feeSmartagent);

  useEffect(() => {
    setFee(feeSmartagent);
  }, [feeSmartagent]);

  let icon: any = SERVICES_ICON[serviceType];
  const iconColor = isSmartAgent ? 'second_green' : 'blue';
  const color = serviceType !== 'MICE' ? iconColor : 'default';

  const isAdditionally = isSmartAgent
    && (serviceType === SERVICETYPE.AIR || serviceType === SERVICETYPE.TRAIN || serviceType === SERVICETYPE.HOTEL)
    && isAdditionalFee
    && !disabled;

  const isAdditionallyReading = isSmartAgent
    && (serviceType === SERVICETYPE.AIR || serviceType === SERVICETYPE.TRAIN || serviceType === SERVICETYPE.HOTEL)
    && isOnlyReadingAdditionalFee
    && !!fee;

  if (isSeat) {
    icon = 'seat';
  }

  if (AdditionalServiceType && AdditionalServiceType !== 0) {
    icon = 'service';
  }

  const setFeeSmartAgent = (value: string) => {
    setFee(+value);

    const typeItemId = isNoteItem ? FIELD.NOTE_ITEM_ID : FIELD.CART_ITEM_ID;

    const responseFee = !isTrip
      ? { [typeItemId]: itemId, AgentFee: value }
      : { TripItemId: itemId, AgentFee: value };

    setAdditionalFeeSmartagent(responseFee);
  };

  const renderPrice = () => {
    if (html) {
      return null;
    }

    return (
      <div className={ styles['price-wrapper'] }>
        <Price
          qaAttr={ qaAttrPrice }
          value={ price }
          marginSmall
          type='bold_24'
        />
        { renderAdditionalPriceText }
      </div>
    );
  };

  const isCustomPrice = typeof renderCustomPrice === 'function' && serviceType === SERVICETYPE.TAXI_VOUCHER;

  const renderCustomPriceContent = () => {
    if (!isCustomPrice) {
      return null;
    }

    return (
      <div className={ styles.actions }>
        <div className={ styles.price }>
          { renderCustomPrice() }
        </div>
      </div>
    );
  };

  const renderAirAdditionalReferralInfo = (link: string | null) => {
    const isCheckTripStatus = status === TRIPSTATUS.CANCELLED || serviceType === SERVICETYPE.FLIGHT_CERTIFICATE;

    if (!link || isCheckTripStatus || versionStatus === VERSIONSTATUS.CANCELLED) return null;

    return (
      <div className={ styles['additional-service-info'] }>
        <div className={ styles['icon-wrapper'] }>
          <Icon type='info' fill='#AFC4E2' color='white'/>
          <Text className={ styles['additional-service-text'] }>
            <span>{LABELS.ADDITIONAL_SERVICES.ADDITIONAL_REFERR_INFO}</span>
            <ExternalLink
              link={ link }
              className={ styles['additional-service-link'] }
              text={ LABELS.ADDITIONAL_SERVICES.ADDITIONAL_REFERR_LINK }
            />
            <span>{LABELS.ADDITIONAL_SERVICES.ADDITIONAL_REFERR_CONT}</span>
          </Text>
        </div>
      </div>
    );
  };

  const optionHtml = typeof renderOption === 'function' ? renderOption() : renderOption;
  const isOptionalHtml = !hideActions && !isCustomPrice;
  const renderPriceContent = renderPrice() || html;

  const renderAdditionallFee = () => {
    if (!isAdditionally) {
      return null;
    }

    return (
      <AdditionalFee
        fee={ fee }
        setFee={ setFeeSmartAgent }
        isTrip={ isTrip }
        isNote={ isNoteItem }
      />
    );
  };

  const renderAdditionalFeeReading = () => {
    if (!isAdditionallyReading) {
      return null;
    }

    return (
      <div
        className={ styles.fee_reading_wrapper }
      >
        <Text
          color='gray'
        >
          { LABELS.ADD }
        </Text>
        <Text
          color='gray'
        >
          { LABELS.RUB(fee) }
        </Text>
      </div>
    );
  };

  const renderOptionalHtml = () => {
    if (!isOptionalHtml) {
      return null;
    }

    return (
      <div className={ styles.actions }>
        { absoluteHtml }
        <div className={ styles.price }>
          { renderPriceContent }
        </div>
        { optionHtml }
        <div>
          { renderAdditionallFee() }
          { renderAdditionalFeeReading() }
        </div>
      </div>
    );
  };

  return (
    <div className={ styles.wrap_layout }>
      <div className={ styles.content }>
        <div className={ styles.main }>
          <div className={ styles.icon }>
            <Icon
              type={ icon }
              qaAttr={ qaAttrIcon }
              alternativeDesign={ isSmartAgent }
              color={ color }
            />
          </div>
          <div className={ styles.children }>
            { children }
            { renderAirAdditionalReferralInfo(referralLink) }
          </div>
        </div>
        { employeesHtml }
      </div>
      { renderOptionalHtml() }
      { renderCustomPriceContent() }
    </div>
  );
};

export default ItemLayout;
