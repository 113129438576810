import React from 'react';
import { Text } from 'new-ui';
import { Moment } from 'moment';
import clsx from 'clsx';

import { getText } from '../../../i18n';

import Timer from '../Timer';

import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import SERVICETYPE from '../../bi/constants/serviceType';

import styles from './index.module.css';

const LABELS = {
  TIME_TO_RESERVATION_CANCEL: getText('note:item.timeToReservationCancel'),
};

interface LayoutTimerProps {
  bookDeadline: string,
  time: Moment,
  onTimeIsUp(): void
  type: string,
}

const LayoutTimer = ({
  bookDeadline,
  time,
  onTimeIsUp,
  type,
}: LayoutTimerProps) => {
  const lineColor = clsx(styles.green_line, {
    [styles.orange_line]: type === SERVICETYPE.HOTEL },
  );

  const wrapper = clsx(styles.green_header, {
    [styles.orange_header]: type === SERVICETYPE.HOTEL },
  );

  return (
    <div className={ wrapper }>
      <div className={ styles.timer }>
        <Text
          type='NORMAL_14'
          color='white'
          qaAttr={ QA_ATTRIBUTES.note.airline.timer }
        >
          { LABELS.TIME_TO_RESERVATION_CANCEL }
        </Text>
        <div className={ styles.time }>
          <Timer
            color='white'
            deadLine={ bookDeadline }
            currentTime={ time || null }
            onTimeIsUp={ onTimeIsUp }
          />
        </div>
      </div>
      <div className={ lineColor }/>
    </div>
  );
};

export default LayoutTimer;
