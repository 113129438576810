import React, { useState } from 'react';
import { Icon, StyledWrapper, Text } from 'new-ui';
import clsx from 'clsx';

import { getText } from '../../../i18n';

import { TYPE_AIR_SEARCH } from '../Menu/AirlineSearchMenu/const';

import { IMulty } from './types';

import styles from './styles/index.module.css';

const LABELS = {
  TRAVELLERS: getText('components:menu.air.main.passengers'),
};

const isMain = (value: string) => value === TYPE_AIR_SEARCH.MAIN;
const isSub = (value: string) => value === TYPE_AIR_SEARCH.SUB;

const MultiPassangers = ({
  sum,
  children,
  type,
}: IMulty) => {
  const [show, setShow] = useState<boolean>(false);

  const wrapper = clsx(styles.wrapper,
    isSub(type) && styles.wrapper_sub);

  const dropdown = clsx(styles.dropdown,
    isSub(type) && styles.dropdown_sub,
  );

  const handleSetShow = () => setShow(prevState => !prevState);

  const renderDropdown = () => {
    if (!show) {
      return null;
    }

    return (
      <StyledWrapper className={ dropdown }>
        { children }
      </StyledWrapper>
    );
  };

  const arrow = show ? 'arrowsUpGray' : 'arrowsDownGray';
  const iconColor = isMain(type) ? 'default' : 'dim_green';

  const renderHeader = () => {
    if (isMain(type)) {
      return (
        <div
          className={ styles.header }
          onClick={ handleSetShow }
        >
          { sum }
          <Icon type={ arrow } color={ iconColor }/>
        </div>
      );
    }

    return (
      <div
        onClick={ handleSetShow }
        className={ styles.sub_header }
      >
        <div className={ styles.sub_travellers }>
          <Text color='dim-gray' type='NORMAL_14'>
            { LABELS.TRAVELLERS }
          </Text>
          <Text color='dim-green'>
            { sum }
          </Text>
        </div>
        <Icon type={ arrow } color={ iconColor }/>
      </div>
    );
  };

  return (
    <div className={ wrapper }>
      { renderHeader() }
      <div>
        { renderDropdown() }
      </div>
    </div>
  );
};

export default MultiPassangers;
