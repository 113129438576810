import React, { useState } from 'react';
import { Datepicker, Checkbox, Text } from 'new-ui';
import { Moment } from 'moment';

import { getText } from '../../../../../i18n';

import { currentDate } from '../../../../bi/utils/formatDate';

import { FIELDS_CHANGE } from '../../../../bi/constants/changeAirTrip';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { AirlineService, AirSearchRoute, AirRoute } from '../../../../bi/types/airline';

import styles from './styles/index.module.css';

const LABELS = {
  DEPARTURE_DATE: getText('components:changeAirTrip.departureDate'),
};

const {
  changeTrip: {
    air: {
      searchMenu: {
        routeDate: {
          activeCheckbox,
          info,
          dateFrom,
        },
      },
    },
  },
} = QA_ATTRIBUTES;

interface SearchDateRouteProps {
  airlineService: AirlineService;
  index: number;
  route: AirSearchRoute;
  visibleCheckbox: boolean;
  defaultRoute: AirRoute;
}

const SearchDateRoute = ({
  airlineService,
  index,
  route,
  visibleCheckbox,
  defaultRoute,
}: SearchDateRouteProps) => {
  const firstSegment = defaultRoute.Segments[0];
  const lastSegment = defaultRoute.Segments[defaultRoute.Segments.length - 1];

  const [disabled, setDisabled] = useState<boolean>(!visibleCheckbox);
  const [date, setDate] = useState<Moment>(route.date);

  const handelUpdateDate = (value: Moment) => {
    setDate(value);
    airlineService.setSearchValue(FIELDS_CHANGE.DATE, { key: index, value });
  };

  const CheckboxVisible = visibleCheckbox && (
    <Checkbox
      qaAttr={ activeCheckbox }
      className={ styles.checkbox }
      onChange={ setDisabled }
      value={ disabled }
    />
  );

  return (
    <div className={ styles.wrapper }>
      { CheckboxVisible }
      <div className={ styles.flight_info }>
        <Text
          qaAttr={ info }
          className={ styles.duration }
          color='gray'
        >
          { `${firstSegment.DepartureCity} (${firstSegment.DepartureAirport.Code}) - ` }
          { `${lastSegment.ArrivalCity} (${lastSegment.ArrivalAirport.Code})` }
        </Text>
      </div>
      <div className={ styles.date }>
        <Datepicker
          qaAttr={ dateFrom }
          wrapperClassName={ styles.wrapper_data }
          inputClassName={ styles.input }
          inputTheme='open'
          withLabel
          placeholder={ LABELS.DEPARTURE_DATE }
          disabled={ !disabled }
          value={ date }
          min={ currentDate() }
          onChange={ value => handelUpdateDate(value as Moment) }
        />
      </div>
    </div>
  );
};

export { SearchDateRoute };
