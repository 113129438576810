import { PROPS, SearchMenuItemTypes, IconType } from 'new-ui';

import { getText } from '../../../i18n';

import { SearchServiceType } from '../utils/searchPanel';

import { DEFAULTMENUTYPE, SEARCHOPTION } from './app';

const SERVICETYPE = {
  AIR: 'Air',
  HOTEL: 'Hotel',
  TRANSFER: 'Transfer',
  TRAIN: 'Train',
  CUSTOM: 'Custom',
  AEROEXPRESS: 'AeroExpress',
  FLIGHT_CERTIFICATE: 'FlightCertificate',
  TAXI_VOUCHER: 'TaxiVoucher',
  TAXI_OPERATION: 'taxioperation',
  TAXI_VOUCHER_FROM_BACK: 'taxivoucher',
  TAXI: 'Taxi',
  AEROEXPRESS_CHARTS: 'Aeroexpress',
  VIP_HALL: 'VipHall',
  AIR_ADDITIONAL_SERVICE: 'AirAdditionalService',
  EVENT: 'MICE',
  MICE: 'Mice',
  INSURANCE: 'Insurance',
};

export enum ANALYTIC_SERVICE_TYPES {
  HOTEL = 'hotel',
  AIR = 'air',
  TRAIN = 'train',
  TRANSFER = 'transfer',
  AERO_EXPRESS = 'aero_express',
  CUSTOM = 'custom',
  TAXI = 'taxi',
  VIP_ROOM = 'vip_room',
  CONFERENCE_HALL = 'conference_hall',
  GROUP_CHECK_IN_HOTEL = 'group_check-in_hotel',
  EVENTS = 'events',
  MICE = 'mice',
  INSURANCE = 'insurance',
}

const SERVICE_TYPE_FOR_TRIP_PLAN = {
  ...SERVICETYPE,
};

const SERVICETYPERU = {
  [SERVICETYPE.AIR]: getText('constants:serviceType.typesRu.air'),
  [SERVICETYPE.HOTEL]: getText('constants:serviceType.typesRu.hotel'),
  [SERVICETYPE.TRANSFER]: getText('constants:serviceType.typesRu.transfer'),
  [SERVICETYPE.MICE]: getText('constants:serviceType.typesRu.mice'),
  [SERVICETYPE.TRAIN]: getText('constants:serviceType.typesRu.train'),
  [SERVICETYPE.TAXI]: getText('constants:serviceType.typesRu.taxi'),
  [SERVICETYPE.CUSTOM]: getText('constants:serviceType.typesRu.custom'),
};

const SEARCH_SERVICE_TYPE: Record<SearchMenuItemTypes, SearchServiceType> = {
  searchTaxi: 'Taxi',
  searchHotel: 'Hotel',
  searchTrain: 'Train',
  searchAirline: 'Air',
  searchAeroexpress: 'AeroExpress',
  searchTransfer: 'Transfer',
};

const SERVICETYPESEARCH = {
  [DEFAULTMENUTYPE.SEARCHAIRLINE]: `${[SERVICETYPE.AIR]}`,
  [DEFAULTMENUTYPE.SEARCHHOTEL]: `${[SERVICETYPE.HOTEL]}`,
  [DEFAULTMENUTYPE.SEARCHTRANSFER]: `${[SERVICETYPE.TRANSFER]}`,
  [DEFAULTMENUTYPE.SEARCHTRAIN]: `${[SERVICETYPE.TRAIN]}`,
  [DEFAULTMENUTYPE.SEARCHAEROEXPRESS]: `${[SERVICETYPE.AEROEXPRESS]}`,
  [DEFAULTMENUTYPE.SEARCH_TAXI]: `${[SERVICETYPE.TAXI]}`,
  [DEFAULTMENUTYPE.SEARCHVIP]: `${[SEARCHOPTION.SEARCHVIP]}`,
  [DEFAULTMENUTYPE.SEARCH_CONFERENCE]: `${[SEARCHOPTION.SEARCH_CONFERENCE]}`,
  [DEFAULTMENUTYPE.SEARCH_GROUP]: `${[SEARCHOPTION.SEARCH_GROUP]}`,
  [DEFAULTMENUTYPE.SEARCH_EVENT]: `${[SEARCHOPTION.SEARCH_EVENT]}`,
};

const SERVICETYPERUMUCH = {
  [SERVICETYPE.AIR]: getText('constants:serviceType.typesRuMuch.air'),
  [SERVICETYPE.HOTEL]: getText('constants:serviceType.typesRuMuch.hotel'),
  [SERVICETYPE.TRAIN]: getText('constants:serviceType.typesRuMuch.train'),
  [SERVICETYPE.TAXI]: getText('constants:serviceType.typesRuMuch.taxi'),
};

const SERVICETYPERUMN = {
  [SERVICETYPE.AIR]: getText('constants:serviceType.typesRuMn.air'),
  [SERVICETYPE.HOTEL]: getText('constants:serviceType.typesRuMn.hotel'),
  [SERVICETYPE.TRANSFER]: getText('constants:serviceType.typesRuMn.transfer'),
  [SERVICETYPE.TRAIN]: getText('constants:serviceType.typesRuMn.train'),
  [SERVICETYPE.TAXI]: getText('constants:serviceType.typesRuMn.taxi'),
  [SERVICETYPE.CUSTOM]: getText('constants:serviceType.typesRuMn.custom'),
  [SERVICETYPE.VIP_HALL]: getText('constants:serviceType.typesRuMn.vipHall'),
};

const CASETYPE = {
  NOMINATIVE: 'nominative',
  GENITIVE: 'genitive',
  ACCUSATIVE: 'accusative',
};

const SERVICETYPECASE = {
  [SERVICETYPE.AIR]: {
    one: {
      [CASETYPE.NOMINATIVE]: getText('constants:serviceType.serviceTypeCase.air.one.nominative'),
      [CASETYPE.GENITIVE]: getText('constants:serviceType.serviceTypeCase.air.one.genitive'),
      [CASETYPE.ACCUSATIVE]: getText('constants:serviceType.serviceTypeCase.air.one.accusative'),
    },
    many: {
      [CASETYPE.NOMINATIVE]: getText('constants:serviceType.serviceTypeCase.air.many.nominative'),
      [CASETYPE.GENITIVE]: getText('constants:serviceType.serviceTypeCase.air.many.genitive'),
      [CASETYPE.ACCUSATIVE]: getText('constants:serviceType.serviceTypeCase.air.many.accusative'),
    },
  },
  [SERVICETYPE.HOTEL]: {
    one: {
      [CASETYPE.NOMINATIVE]: getText('constants:serviceType.serviceTypeCase.hotel.one.nominative'),
      [CASETYPE.GENITIVE]: getText('constants:serviceType.serviceTypeCase.hotel.one.genitive'),
      [CASETYPE.ACCUSATIVE]: getText('constants:serviceType.serviceTypeCase.hotel.one.accusative'),
    },
    many: {
      [CASETYPE.NOMINATIVE]: getText('constants:serviceType.serviceTypeCase.hotel.many.nominative'),
      [CASETYPE.GENITIVE]: getText('constants:serviceType.serviceTypeCase.hotel.many.genitive'),
      [CASETYPE.ACCUSATIVE]: getText('constants:serviceType.serviceTypeCase.hotel.many.accusative'),
    },
  },
  [SERVICETYPE.TRANSFER]: {
    one: {
      [CASETYPE.NOMINATIVE]: getText('constants:serviceType.serviceTypeCase.transfer.one.nominative'),
      [CASETYPE.GENITIVE]: getText('constants:serviceType.serviceTypeCase.transfer.one.genitive'),
      [CASETYPE.ACCUSATIVE]: getText('constants:serviceType.serviceTypeCase.transfer.one.accusative'),
    },
    many: {
      [CASETYPE.NOMINATIVE]: getText('constants:serviceType.serviceTypeCase.transfer.many.nominative'),
      [CASETYPE.GENITIVE]: getText('constants:serviceType.serviceTypeCase.transfer.many.genitive'),
      [CASETYPE.ACCUSATIVE]: getText('constants:serviceType.serviceTypeCase.transfer.many.accusative'),
    },
  },
  [SERVICETYPE.TRAIN]: {
    one: {
      [CASETYPE.NOMINATIVE]: getText('constants:serviceType.serviceTypeCase.train.one.nominative'),
      [CASETYPE.GENITIVE]: getText('constants:serviceType.serviceTypeCase.train.one.genitive'),
      [CASETYPE.ACCUSATIVE]: getText('constants:serviceType.serviceTypeCase.train.one.accusative'),
    },
    many: {
      [CASETYPE.NOMINATIVE]: getText('constants:serviceType.serviceTypeCase.train.many.nominative'),
      [CASETYPE.GENITIVE]: getText('constants:serviceType.serviceTypeCase.train.many.genitive'),
      [CASETYPE.ACCUSATIVE]: getText('constants:serviceType.serviceTypeCase.train.many.accusative'),
    },
  },
  [SERVICETYPE.CUSTOM]: {
    one: {
      [CASETYPE.NOMINATIVE]: '',
      [CASETYPE.GENITIVE]: '',
      [CASETYPE.ACCUSATIVE]: '',
    },
    many: {
      [CASETYPE.NOMINATIVE]: '',
      [CASETYPE.GENITIVE]: '',
      [CASETYPE.ACCUSATIVE]: '',
    },
  },
};

const getNeedCase = (service: string, type: string, num: number) => {
  const value = SERVICETYPECASE[service];

  if (num === 1) {
    return value.one[type];
  }

  return value.many[type];
};

const SERVICETYCASE = {
  [SERVICETYPE.AIR]: (type = CASETYPE.NOMINATIVE, num = 1) => getNeedCase(SERVICETYPE.AIR, type, num),
  [SERVICETYPE.HOTEL]: (type = CASETYPE.NOMINATIVE, num = 1) => getNeedCase(SERVICETYPE.HOTEL, type, num),
  [SERVICETYPE.TRANSFER]: (type = CASETYPE.NOMINATIVE, num = 1) => getNeedCase(SERVICETYPE.TRANSFER, type, num),
  [SERVICETYPE.TRAIN]: (type = CASETYPE.NOMINATIVE, num = 1) => getNeedCase(SERVICETYPE.TRAIN, type, num),
  [SERVICETYPE.CUSTOM]: (type = CASETYPE.NOMINATIVE, num = 1) => getNeedCase(SERVICETYPE.CUSTOM, type, num),
};

const SERVICESICON = {
  AIR: 'smartway-airplane',
  HOTEL: 'smartway-hotel',
  TRANSFER: 'smartway-transfer',
  TRAIN: 'smartway-train',
  CUSTOM: 'smartway-reports',
  AEROEXPRESS: 'smartway-aeroexpress-temp-big',
};

const SERVICESSEARCHID = {
  AIR: '-airline',
  HOTEL: '-hotel',
  AEROEXPRESS: '-aeroexpress',
  TRAIN: '-train',
  TAXI: '-taxi',
  TRANSFER: '-transfer',
};

const FLIGHT_CERTIFICATE_TYPES = {
  NEED_PURCHASE: 'NeedPurchase',
  NOT_CONFIRMED: 'NotConfirmed',
  CAN_DOWNLOAD: 'CanDownload',
  UNAVAILABLE: 'Unavailable',
  WAITING: 'Waiting',
  FAILED: 'Failed',
};

const DIALOG_TYPES = {
  CANCEL: 'cancel',
  CALCULATION_CANCEL: 'calculationCancel',
  EDIT: 'edit',
  FLIGHT_CERTIFICATE: 'flightCertificate',
};

const SERVICES_ICON: { [key: string]: IconType } = {
  [SERVICETYPE.AIR]: PROPS.ICON.TYPES.ITEM.AIR as IconType,
  [SERVICETYPE.HOTEL]: PROPS.ICON.TYPES.ITEM.HOTEL as IconType,
  [SERVICETYPE.TRANSFER]: PROPS.ICON.TYPES.ITEM.TRANSFER as IconType,
  [SERVICETYPE.TRAIN]: PROPS.ICON.TYPES.ITEM.TRAIN as IconType,
  [SERVICETYPE.CUSTOM]: PROPS.ICON.TYPES.SEARCH.CONFERENCE as IconType,
  [SERVICETYPE.AEROEXPRESS]: PROPS.ICON.TYPES.ITEM.AEROEXPRESS as IconType,
  [SERVICETYPE.FLIGHT_CERTIFICATE]: PROPS.ICON.TYPES.MENU.NOTE as IconType,
  [SERVICETYPE.TAXI]: PROPS.ICON.TYPES.ITEM.TAXI as IconType,
  [SERVICETYPE.TAXI_VOUCHER]: PROPS.ICON.TYPES.ITEM.TAXI as IconType,
  [SERVICETYPE.VIP_HALL]: PROPS.ICON.TYPES.ITEM.VIP_HALL as IconType,
  [SERVICETYPE.AIR_ADDITIONAL_SERVICE]: 'service',
  [SERVICETYPE.EVENT]: 'itemMice',
  [SERVICETYPE.INSURANCE]: 'service',
};

const SERVICES_ICON_FOR_TRIP_PLAN = {
  [SERVICE_TYPE_FOR_TRIP_PLAN.AIR]: PROPS.ICON.TYPES.ITEM.AIR,
  [SERVICE_TYPE_FOR_TRIP_PLAN.HOTEL]: PROPS.ICON.TYPES.ITEM.HOTEL,
  [SERVICE_TYPE_FOR_TRIP_PLAN.TRANSFER]: PROPS.ICON.TYPES.ITEM.TRANSFER,
  [SERVICE_TYPE_FOR_TRIP_PLAN.TRAIN]: PROPS.ICON.TYPES.ITEM.TRAIN,
  [SERVICE_TYPE_FOR_TRIP_PLAN.CUSTOM]: PROPS.ICON.TYPES.SEARCH.CONFERENCE,
  [SERVICE_TYPE_FOR_TRIP_PLAN.AEROEXPRESS]: PROPS.ICON.TYPES.ITEM.AEROEXPRESS,
  [SERVICE_TYPE_FOR_TRIP_PLAN.FLIGHT_CERTIFICATE]: PROPS.ICON.TYPES.MENU.NOTE,
  [SERVICE_TYPE_FOR_TRIP_PLAN.TAXI]: PROPS.ICON.TYPES.ITEM.TAXI,
  [SERVICE_TYPE_FOR_TRIP_PLAN.TAXI_VOUCHER]: PROPS.ICON.TYPES.ITEM.TAXI,
  [SERVICE_TYPE_FOR_TRIP_PLAN.VIP_HALL]: PROPS.ICON.TYPES.ITEM.VIP_HALL,
  [SERVICE_TYPE_FOR_TRIP_PLAN.EVENT]: 'itemMice',
};

const SMART_AGENT_SERVICE_TYPE = {
  AVIA: 'avia',
  TRAIN: 'train',
  TRANSFER: 'transfer',
  HOTEL: 'hotel',
  VIP_HALL: 'viphall',
  ALL: 'all',
};

export const ANALYTIC_SERVICE_TYPE_MATCH = {
  [SERVICETYPE.HOTEL]: ANALYTIC_SERVICE_TYPES.HOTEL,
  [SERVICETYPE.AIR]: ANALYTIC_SERVICE_TYPES.AIR,
  [SERVICETYPE.TRAIN]: ANALYTIC_SERVICE_TYPES.TRAIN,
  [SERVICETYPE.TRANSFER]: ANALYTIC_SERVICE_TYPES.TRANSFER,
  [SERVICETYPE.AEROEXPRESS]: ANALYTIC_SERVICE_TYPES.AERO_EXPRESS,
  [SERVICETYPE.CUSTOM]: ANALYTIC_SERVICE_TYPES.CUSTOM,
  [SERVICETYPE.TAXI_VOUCHER]: ANALYTIC_SERVICE_TYPES.TAXI,
  [SERVICETYPE.VIP_HALL]: ANALYTIC_SERVICE_TYPES.VIP_ROOM,
  [SERVICETYPE.MICE]: ANALYTIC_SERVICE_TYPES.MICE,
};

export const ANALYTIC_SERVICE_TYPE_MATCH_SEARCH = {
  [DEFAULTMENUTYPE.SEARCHAIRLINE]: ANALYTIC_SERVICE_TYPES.AIR,
  [DEFAULTMENUTYPE.SEARCHTRAIN]: ANALYTIC_SERVICE_TYPES.TRAIN,
  [DEFAULTMENUTYPE.SEARCHHOTEL]: ANALYTIC_SERVICE_TYPES.HOTEL,
  [DEFAULTMENUTYPE.SEARCH_TAXI]: ANALYTIC_SERVICE_TYPES.TAXI,
  [DEFAULTMENUTYPE.SEARCHAEROEXPRESS]: ANALYTIC_SERVICE_TYPES.AERO_EXPRESS,
  [DEFAULTMENUTYPE.SEARCHTRANSFER]: ANALYTIC_SERVICE_TYPES.TRANSFER,
  [DEFAULTMENUTYPE.SEARCHVIP]: ANALYTIC_SERVICE_TYPES.VIP_ROOM,
  [DEFAULTMENUTYPE.SEARCH_CONFERENCE]: ANALYTIC_SERVICE_TYPES.CONFERENCE_HALL,
  [DEFAULTMENUTYPE.SEARCH_EVENT]: ANALYTIC_SERVICE_TYPES.EVENTS,
  [DEFAULTMENUTYPE.SEARCH_GROUP]: ANALYTIC_SERVICE_TYPES.GROUP_CHECK_IN_HOTEL,
};

export {
  SERVICETYPE as default,
  SERVICETYPE,
  SERVICETYPERU,
  SERVICETYPERUMN,
  SERVICETYCASE,
  CASETYPE,
  SERVICETYPERUMUCH,
  SERVICETYPESEARCH,
  SERVICESICON,
  SERVICESSEARCHID,
  FLIGHT_CERTIFICATE_TYPES,
  DIALOG_TYPES,
  SERVICES_ICON,
  SEARCH_SERVICE_TYPE,
  SERVICES_ICON_FOR_TRIP_PLAN,
  SERVICE_TYPE_FOR_TRIP_PLAN,
  SMART_AGENT_SERVICE_TYPE,
};
