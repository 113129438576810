import React from 'react';
import { Text, BookMark } from 'new-ui';
import { getText } from '../../../i18n';

import { formatRangeDateWithSimplicity } from '../../bi/utils/formatDate';

import { SPECIFIC_CART_TYPES } from '../../bi/constants/cart';
import SERVICETYPE from '../../bi/constants/serviceType';

import styles from './styles.module.css';

interface BookMarkAddingTripProps {
  trips: {
    TripId: number,
    Name: string,
    CheckInDate: string,
    CheckOutDate: string,
  }[],
  type?: string | null,
  loading?: boolean,
  tripId: string | number | null,
  onClose?: () => void,
  serviceType: string,
}

const LABELS = {
  ORDER_FOR_A_TRIP: getText('components:trip.orderForATrip'),
};

const BookMarkAddingTrip = ({ trips, type, tripId, loading, onClose, serviceType }: BookMarkAddingTripProps) => {
  const trip = trips.find(item => item.TripId === tripId);

  if (!trip) return null;

  const description = `${trip!.Name} ${formatRangeDateWithSimplicity(trip!.CheckInDate, trip!.CheckOutDate)}`;
  const wrapperClasses = [styles.wrapper];

  if (loading) {
    wrapperClasses.push(styles.loading);
  }

  const content = () => (
    <>
      <Text color='white' type='NORMAL_14' className={ styles.no_wrap_text }>
        {LABELS.ORDER_FOR_A_TRIP}
      </Text>
      <Text color='white' type='SEMIBOLD_14' className={ styles.no_wrap_text }>
        {description}
      </Text>
    </>
  );

  const needClose = (
    type === SPECIFIC_CART_TYPES.APPROVER ||
    type === SPECIFIC_CART_TYPES.VIEWER ||
    serviceType === SERVICETYPE.INSURANCE
  )
    ? null
    : onClose;

  return (
    <BookMark
      loading={ loading }
      onClose={ needClose }
      renderContent={ content }
      className={ wrapperClasses.join(' ') }
    />
  );
};

export { BookMarkAddingTrip };
