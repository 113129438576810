import { getText } from '../../../i18n';

const CHECKIN = 'checkin';
const CHECKOUT = 'checkout';
const TRAVELLERS_COUNT = 'travellersCount';
const CUSTOM_CHECKIN = 'checkin.time';
const CUSTOM_CHECKOUT = 'checkout.time';
const TIME = 'time';
const DATE = 'date';
const LABELS = {
  [CHECKIN]: 'заезда',
  [CHECKOUT]: 'выезда',
};

const AMENITITESRU: { [key: string]: string } = {
  Bath: getText('constants:hotelSearch.amenitiesRu.bath'),
  Fitness: getText('constants:hotelSearch.amenitiesRu.fitness'),
  Internet: getText('constants:hotelSearch.amenitiesRu.internet'),
  Meal: getText('constants:hotelSearch.amenitiesRu.meal'),
  Parking: getText('constants:hotelSearch.amenitiesRu.parking'),
  Pool: getText('constants:hotelSearch.amenitiesRu.pool'),
  Spa: getText('constants:hotelSearch.amenitiesRu.spa'),
};

const TYPERU: { [key: string]: string } = {
  Hotel: getText('constants:hotelSearch.typeRu.hotel'),
  Hostel: getText('constants:hotelSearch.typeRu.hostel'),
  Guesthouse: getText('constants:hotelSearch.typeRu.guestHouse'),
  Sanatorium: getText('constants:hotelSearch.typeRu.sanatorium'),
  Resort: getText('constants:hotelSearch.typeRu.resort'),
  'Mini-hotel': getText('constants:hotelSearch.typeRu.miniHotel'),
  Apartment: getText('constants:hotelSearch.typeRu.apartments'),
};

const RATING_RU: { [key: string]: string } = {
  8.5: getText('constants:hotelSearch.ratingRu.8'),
  7: getText('constants:hotelSearch.ratingRu.7'),
  6: getText('constants:hotelSearch.ratingRu.6'),
};

const SELECTTRAVELLERS: { [key: number]: { label: string, value: number, roomCount: number }[] } = {
  1: [
    { label: 'default', value: 1, roomCount: 1 },
  ],
  2: [
    { label: getText('constants:hotelSearch.selectTravellers.2.one'), value: 1, roomCount: 2 },
    { label: getText('constants:hotelSearch.selectTravellers.2.two'), value: 2, roomCount: 1 },
  ],
  3: [
    { label: getText('constants:hotelSearch.selectTravellers.3.one'), value: 1, roomCount: 3 },
    { label: getText('constants:hotelSearch.selectTravellers.3.two'), value: 2, roomCount: 2 },
    { label: getText('constants:hotelSearch.selectTravellers.3.three'), value: 3, roomCount: 1 },
  ],
  4: [
    { label: getText('constants:hotelSearch.selectTravellers.4.one'), value: 1, roomCount: 4 },
    { label: getText('constants:hotelSearch.selectTravellers.4.two'), value: 2, roomCount: 2 },
    { label: getText('constants:hotelSearch.selectTravellers.4.four'), value: 4, roomCount: 1 },
  ],
  5: [
    { label: getText('constants:hotelSearch.selectTravellers.5.one'), value: 1, roomCount: 5 },
    { label: getText('constants:hotelSearch.selectTravellers.5.two'), value: 2, roomCount: 3 },
    { label: getText('constants:hotelSearch.selectTravellers.5.three'), value: 3, roomCount: 2 },
    { label: getText('constants:hotelSearch.selectTravellers.5.fifth'), value: 5, roomCount: 1 },
  ],
  6: [
    { label: getText('constants:hotelSearch.selectTravellers.6.one'), value: 1, roomCount: 6 },
    { label: getText('constants:hotelSearch.selectTravellers.6.two'), value: 2, roomCount: 3 },
    { label: getText('constants:hotelSearch.selectTravellers.6.three'), value: 3, roomCount: 2 },
    { label: getText('constants:hotelSearch.selectTravellers.6.sixth'), value: 6, roomCount: 1 },
  ],
};

const SELECT_BED_TYPE: { label: string, value: number }[] = [
  { label: getText('constants:hotelSearch.selectBedType.2'), value: 1 },
  { label: getText('constants:hotelSearch.selectBedType.3'), value: 2 },
];

const HOTEL_RADIUS_SEARCH: { [key: string]: number } = {
  SMALL: 3,
  BIG: 10,
};

export {
  TYPERU as default,
  TYPERU,
  AMENITITESRU,
  SELECTTRAVELLERS,
  CHECKOUT,
  CHECKIN,
  TIME,
  DATE,
  LABELS,
  RATING_RU,
  SELECT_BED_TYPE,
  HOTEL_RADIUS_SEARCH,
  TRAVELLERS_COUNT,
  CUSTOM_CHECKIN,
  CUSTOM_CHECKOUT,
};
