import React from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../i18n';

import { serviceProviderBonusCard } from '../../bi/utils/airline';

import SERVICETYPE from '../../bi/constants/serviceType';

import { BonusesType } from '../../bi/services/employee/types';

const LABELS = {
  BONUS_CART: (bonus: string) => getText('components:employeeList.bonusCard', { bonus }),
};

interface EmployeeBonusCardProps {
  routes: any[]; // TODO заменить после типизации cart-wp
  Bonuses: BonusesType[];
  ServiceType: string;
}

type ID = {
  Airline: { ID: string };
};

const bonusType = (
  Type: string,
  type: string,
  ServiceType: string,
) => Type === type && type === ServiceType;

const checkType = (
  { Type, Code }: { Type: string, Code: string },
  routes: any[],
  ServiceType: string,
) => Type
  && ((routes && bonusType(Type, SERVICETYPE.AIR, ServiceType)
  && (routes[0].Segments.find(({ Airline: { ID } }: ID) => ID === Code) || serviceProviderBonusCard(routes[0].Segments, Code)))
    || bonusType(Type, SERVICETYPE.TRAIN, ServiceType));

const EmployeeBonusCard = ({
  routes,
  Bonuses,
  ServiceType,
}: EmployeeBonusCardProps) => {
  const bonusHtml = ({ Id, Number }: { Id: number, Number: string }) => (
    <div key={ Id } >
      <Text type='NORMAL_14' color='gray'>
        { LABELS.BONUS_CART(Number) }
      </Text>
    </div>
  );

  const bonusesContent = Bonuses.map((bonus) => checkType(bonus, routes, ServiceType) && bonusHtml(bonus));

  return (
    <>
      { bonusesContent }
    </>
  );
};

export default EmployeeBonusCard;
