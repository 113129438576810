interface MixPanelModel {
  [key: string]: any;
}

declare global {
  interface Window {
    mixpanel?: {
      isDemo?: boolean;
      track: (action: string, model: MixPanelModel) => void;
      reset: () => void;
    };
  }
}

class MixPanel {
  static track(action: string, model: MixPanelModel): void {
    if (window.mixpanel && !window.mixpanel.isDemo) {
      window.mixpanel.track(action, model);
    }
  }

  static reset(): void {
    if (window.mixpanel) {
      window.mixpanel.reset();
    }
  }
}

export default MixPanel;
