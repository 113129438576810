const ACTION = {
  UPDATECHECKIN: 'checkin.date',
  UPDATECUSTOMCHECKIN: 'checkin.time',
  UPDATECHECKOUT: 'checkout.date',
  UPDATECUSTOMCHECKOUT: 'checkout.time',
  UPDATE_TRAVELLERS_COUNT: 'travellersCount',
  UPDATEADULT: 'adult',
  UPDATE_BREAKFAST: 'breakfast',
  UPDATE_FREE_CANCELLATION: 'freeCancellation',
  UPDATE_COMMENT: 'comment',
  UPDATEREGIONLABEL: 'region.label',
  UPDATEREGIONSELECTED: 'region.selected',
  UPDATEREGIONSUGGEST: 'region',
  UPDATE_REGION_SUGGEST_LOADING: 'region.loading',
  INITNEWSEARCH: 'init.search',
  UPDATEREGIONSETTINGS: 'region.settings',
  SETIMMEDIATESEARCH: 'immediate',
  BACKTOSEARCHLIST: 'search.list',
  REGION_COMPLETE: 'region.complete',
  REGION_FAIL: 'region.fail',
  SET_GUID: 'update.guid',

  CLEARREGION: 'region.clear',
  CLEARHOTEL: 'hotel.clear',

  UPDATE_HOTELS_SEARCH_PAGE: 'refresh.hotels.search.page',
  UPDATE_HOTELS_RESULT_PAGE: 'refeesh.hotels.result.page',

  LOADHOTEL: 'load.hotel',
  STARTLOADHOTEL: 'start.load.hotel',
  STARTLOADHOTELRATE: 'start.load.hotel.rate',
  UPDATEHOTELROOMGROUPS: 'update.hotel.room.groups',
  UPDATE_HOTEL_IMPORTANT_INFO: 'update.hotel.importantInfo',
  HOTELNOTFOUND: 'hotel.notFound',
  RATESNOTFOUND: 'rates.notFound',
  UPDATEROOMCOUNT: 'update.hotel.room',
  UPDATERATECOUNTSELECT: 'update.rate.select',
  UPDATEHASVATFILTER: 'update.hasVat.filter',
  SET_ERROR_HOTEL_RESEARCH: 'error.hotel.research',

  UPDATEHOTELONLINEFILTER: 'hotel.filters.online',
  UPDATEHOTELBREAKFASTFILTER: 'hotel.filters.breakfast',
  UPDATEHOTELCANCELLATIONFILTER: 'hotel.filters.has_cancellation',
  UPDATEHOTELFILTERS: 'hotel.filters.update',
  UPDATEHOTELFILTER: 'hotel.filter.update',

  STARTSEARCH: 'start.search',
  NEWSEARCH: 'new.search',
  BED_TYPE_SEARCH: 'bedType.search',
  APPROVAL_REQUEST_SEARCH: 'approval.request.search',
  SCHEME_LOADING: 'search.approve.schemeLoading',
  SET_SCHEME_HOTEL_STORE: 'set.schemeHotelStore',
  SCHEME_UPDATE_REGION_SUGGEST: 'update.schemeHotelSuggest',
  SCHEME_HOTEL_STORE_RESET: 'reset.schemeHotelStore',
  STARTRADIUSSEARCH: 'star.radius.search',
  START_RADIUS_SEARCH_HOTEL: 'start.radius.search.hotel',
  UPDATE_RADIUS_SEARCH_PARAMS: 'update.radius.search.params',
  ABORTRADIUSSEARCH: 'abort.radius.search',
  INIT_DOUBLE_RADIUS_SEARCH: 'init.double.radius.search',
  LOADING_CONTRACT_HOTELS_REQUEST: 'loadingContractHotelsRequest',
  UPDATE_REGION_SEARCH_ID: 'update.updateRegionSearchId',
  TEXT_TEMPLATE_WARNING: 'text.template.warning',
  INIT_RADIUS_SEARCH_HOTELS: 'INIT_RADIUS_SEARCH_HOTELS',

  UPDATESORTBY: 'sortBy',

  UPDATEPRICEFILTER: 'filters.price',
  UPDATESMARTHOTELFILTER: 'filters.smartHotel',
  UPDATE_PRICE_GUARANTEED_FILTER: 'filters.priceGuaranteed',
  UPDATEHOTELNAMEFILTER: 'filters.hotelName',
  UPDATESTARSFILTER: 'filters.stars',
  UPDATERATINGFILTER: 'filters.rating',
  UPDATEAMENITIESFILTER: 'filters.amenities',
  UPDATETYPEFILTER: 'filters.type',
  UPDATEFAVORITEFILTER: 'filters.favorite',
  UPDATECANCELLATIONFILTER: 'filters.has_cancellation',
  DELETE_TAGS: 'filters.tags.delete',
  RESET_FILTERS: 'filters.reset',
  HAS_VAT_FILTERS: 'filters.hasVat',
  UPDATE_TRAVEL_POLICY_FILTER: 'filters.travelPolicy',
  UPDATE_ONLINE_FILTER: 'filters.online',
  UPDATE_BREAKFAST_FILTER: 'filters.breakfast',
  UPDATE_RADIUS_FILTER: 'filters.radius',
  UPDATE_RECOMMEND_HOTELS: 'filters.recommendHotels',

  UPDATE_VISIBLE_HOTEL_ON_MAP: 'filter.visibleHotelsOnMap',
  UPDATE_DISTANCE_FROM_POINT: 'updateDistanceFromPoint',

  PAGING: 'paging',

  LOADCOMPLETE: 'complete',
  LOAD_COMPLETE_RESULT_PAGE: 'complete.result.page',

  UPDATEHISTORY: 'updateHistory',
  SETHISTORYINSTANCE: 'historyInstance',
  CHANGEFAVORITESTATE: 'favorite.change',
  UPDATEHOTELFAVORITE: 'update.hotel.favorite',

  LOADINGSTATICHOTEL: 'loading.static.hotel',
  ADDSTATICHOTEL: 'add.static.hotel',
  REMOVESTATICHOTEL: 'remove.static.hotel',
  ADDTOFAVORITESTATICHOTEL: 'add.to.favorite.static.hotel',
  REMOVEFROMFAVORITESTATICHOTEL: 'remove.from.favorite.static.hotel',

  SHOW_FORM_HOTEL_SEARCH_DIALOG: 'showFormHotelSearchDialog',
  MESSAGE_SEND: 'messageSend',
  SELECT_EMPLOYEE: 'selectEmployee',
  REMOVE_EMPLOYEE: 'removeEmployee',
  LOADING_REQUEST: 'loadingRequest',

  UPDATECOORDSPOINT: 'update.coordsPoint',
  UPDATEVIEWMODE: 'update.viewMode',
  TOGGLE_SET_CACHE_ON_MAP: 'TOGGLE_SET_CACHE_ON_MAP',
  SET_LAST_HOTELS_SEARCH: 'SET_LAST_HOTELS_SEARCH',
  SET_IS_REFUND: 'SET_IS_REFUND',

  CHANGE_RESERVED_COUNT: 'update.count',
};

export default ACTION;
