// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown, Icon, PROPS, Text } from 'new-ui';
import { observer } from 'mobx-react';
import MediaQuery from 'react-responsive';
import clsx from 'clsx';

import { withStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import ANIMATION from '../../../../bi/constants/animation';
import { DEFAULTMENUTYPE } from '../../../../bi/constants/app';

import { isSmartAgent } from '../../../../bi/utils/env';

import styles from './styles/index.module.css';

const {
  TEXT: {
    TYPES: { NORMAL_14, NORMAL_16_140 },
  },
  DROPDOWN: {
    THEME: {
      TERTIARY,
    },
  },
} = PROPS;

@withStores([MOBX_STORES.UI_SETTINGS])
@observer
class ItemWrap extends Component {
  static propTypes = {
    showBadge: PropTypes.bool,
    count: PropTypes.number,
    href: PropTypes.string.isRequired,
    label: PropTypes.object.isRequired,
    getIcon: PropTypes.func.isRequired,
    getIconColor: PropTypes.func,
    items: PropTypes.array,
    type: PropTypes.string,
    mainMenu: PropTypes.bool,
    subItem: PropTypes.bool,
    className: PropTypes.string,
    classNameWrapper: PropTypes.string,
    qaAttr: PropTypes.string,
    qaAttrCounter: PropTypes.string,
    qaAttrIcon: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    getShowElementForSmartagent: PropTypes.bool,
    stores: PropTypes.shape({
      uiSettingsStore: PropTypes.object.isRequired,
    }),
  };

  static defaultProps = {
    subItem: false,
    items: [],
    string: '',
    count: 0,
    getIconColor: () => {},
    className: '',
    classNameWrapper: '',
    qaAttr: '',
    qaAttrCounter: '',
    qaAttrIcon: '',
    getShowElementForSmartagent: false,
  };

  state = {
    animationClass: '',
  };

  componentDidUpdate(prevProps) {
    if (prevProps.count !== this.props.count) {
      this.setState({
        animationClass: ANIMATION.BOUNCEIN,
      });
    }
  }

  handleAnimatedEnd = () => {
    this.setState({
      animationClass: '',
    });
  };

  renderLinkContent = (item, isNested) => {
    const { animationClass } = this.state;
    const { getIcon, getIconColor, qaAttrCounter, qaAttrIcon, getShowElementForSmartagent } = this.props;
    const { label, count, showBadge = false, type, mainMenu } = item;

    const nextClassNames = !isNested ? styles.text : '';
    const textType = mainMenu || (isSmartAgent && getShowElementForSmartagent) ? NORMAL_16_140 : NORMAL_14;

    const labelResponsive = (
      <MediaQuery minWidth={ 920 }>
        { label }
      </MediaQuery>
    );

    return (
      <>
        <div className={ animationClass } onAnimationEnd={ this.handleAnimatedEnd }>
          <Icon
            qaAttr={ qaAttrIcon }
            qaAttrCounter={ qaAttrCounter }
            showBadge={ showBadge }
            badgeCount={ count }
            className={ styles.icon }
            type={ getIcon(type) }
            color={ getIconColor() }
          />
        </div>
        <Text className={ nextClassNames } type={ textType }>{ labelResponsive }</Text>
      </>
    );
  };

  renderLinkWrap = (item, isNested) => {
    const {
      stores: {
        uiSettingsStore: {
          settings: { reportRedirectUrl },
        },
      },
    } = this.props;
    const { href, type, mainMenu, subItem, className } = item;

    const wrapClassNames = clsx(styles.link, {
      [styles.item]: isNested,
      [className]: className,
    });

    const activeClassNames = clsx({
      [styles.active]: subItem || mainMenu,
      [styles.subitem]: subItem,
    });

    const activeSmartdesk = clsx(activeClassNames, styles.active_first);

    const content = this.renderLinkContent(item, isNested);

    if (type === DEFAULTMENUTYPE.REPORT && reportRedirectUrl) {
      return (
        <a href={ reportRedirectUrl } className={ wrapClassNames } rel='noreferrer noopener'>
          { content }
        </a>
      );
    }

    if (type === DEFAULTMENUTYPE.SEARCH || type === DEFAULTMENUTYPE.ACCOUNTING || type === DEFAULTMENUTYPE.APPLICATION) {
      return (
        <div className={ wrapClassNames }>
          { content }
        </div>
      );
    }

    if (type === DEFAULTMENUTYPE.SMARTDESC) {
      return (
        <NavLink
          to={ href }
          className={ wrapClassNames }
          activeClassName={ activeSmartdesk }
        >
          { content }
        </NavLink>
      );
    }

    return (
      <NavLink
        to={ href }
        className={ wrapClassNames }
        activeClassName={ activeClassNames }
      >
        {content}
      </NavLink>
    );
  };

  renderItem = (item, isNested) => {
    const { onClick, qaAttr, classNameWrapper } = this.props;
    const { items, typeItem, qaAttr: qaAttrDropDown } = item;

    const itemsContent = () => (
      <Dropdown
        onClick={ () => {} }
        theme={ TERTIARY }
        renderLabel={ () => this.renderItem({ ...item, items: [] }) }
      >
        <div>
          { items.map((i) => (
            <div key={ i.type }>
              { this.renderItem({ ...i, href: i.url, label: i.title, items: [], mainMenu: false, typeItem: i.type }, true) }
            </div>),
          ) }
        </div>
      </Dropdown>
    );

    const content = items.length ? itemsContent() : this.renderLinkWrap(item, isNested);

    return (
      <div
        className={ `${styles.wrapper} ${items.length ? styles.search : ''} ${classNameWrapper}` }
        onClick={ () => onClick(typeItem) }
        data-qa={ !items.length ? (qaAttrDropDown || qaAttr) : '' }
      >
        { content }
      </div>
    );
  };

  render() {
    // just to shut eslint
    const { href, label, items, type, mainMenu, subItem, className, showBadge } = this.props;

    return this.renderItem({ ...this.props, href, label, items, type, mainMenu, subItem, className, showBadge });
  }
}

export default ItemWrap;
