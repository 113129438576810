import React from 'react';
import { Dialog, Text } from 'new-ui';

import { getText } from '../../../i18n';

import styles from './styles.module.css';

const LABELS = {
  NO_APPROVING: getText('approve:noApproving'),
};

const NAME_ERROR = {
  NO_DATA: getText('approve:errors.noData'),
  ERROR: getText('approve:errors.error'),
};

const ErrorReportDialog = ({ errorDialog, onErrorDialog }: { errorDialog: any, onErrorDialog: any }) => {
  const { msg } = errorDialog;
  const content = msg === NAME_ERROR.NO_DATA ? (
    <>
      <Text type={ 'bold_20' } className={ styles['dialog-header'] }>{ msg }</Text>
      <Text>{LABELS.NO_APPROVING}</Text>
    </>
  ) : (
    <Text type={ 'bold_20' }>{ msg }</Text>
  );

  return (
    <Dialog
      show={ errorDialog.show }
      showClosing
      onChange={ (show) => onErrorDialog(show, errorDialog.msg) }
    >
      <div className={ styles['dialog-content'] }>
        { content }
      </div>
    </Dialog>
  );
};

export default ErrorReportDialog;
