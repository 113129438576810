import { MomentInput } from 'moment/moment';
import { getText } from '../../../i18n';

import { compareLowerStrings } from './strings';
import { NAMESAKES } from '../constants/employee';
import { getMoment, diffYears, formatDate, defaultPattern } from './formatDate';
import parseJsonString from './parseJsonString';
import { AIRLINE_PROVIDERS } from '../constants/airline';
import { EmployeesDocument, ICommonEmployees } from '../types/employees';
import { ICompanyShort } from '../services/settings/types';
import { ICompanies, IDepartment, IPrepareSourcesItem, ISourcesItem } from '../services/checkout/types';
import { IDocumentUser } from '../services/employee/types';

const DisplayFF = {
  BIRTHDAY: 'birthday',
  DOCUMENTS: 'documents',
  FULLNAME: 'fullName',
};

const LABELS = {
  DATE_BIRTHDAY: getText('components:employeeSuggest.birthday'),
  DOCUMENT: getText('components:employeeSuggest.document'),
};

export interface IEmployeeFullNameObj {
  Surname?: string;
  Name?: string;
  Patronymic?: string;
}

export interface IEmployeesItem {
  Id: number;
  ExternalId: string;
  Surname: string;
  Email: string;
  Name: string;
  Patronymic: string;
  Status: string;
  Sex: string;
  Birthday: string;
  Documents: EmployeesDocument[];
  ApprovalSchemeId: number;
  ProjectId: null | number;
  Tags: any;
  Companies: ICompanyShort[];
  Number: string;
  Uuid: null | any;
  Position: string;
  DepartmentId: number | null;
  CompanyId: number;
}

export interface IUnderageEmployeesItem {
  Employee: IEmployee
  Documents: IDocuments
  errors: IErrors
}

export interface IEmployee { // TODO вынести и понять root это или нет
  Id: number
  ExternalId: any
  Surname: string
  Name: string
  Patronymic: string
  MobilePhone: string
  Email: string
  Code: string
  Code3: string
  Birthday: string
  Underage: boolean
  Sex: string
  Status: string
  EmployeeNumber: string
  Bonuses: any[]
  Documents: EmployeesDocument[]
  Companies: ICompanies[]
  Departments: IDepartment[]
  Projects: IProject[]
  Tags: any[]
  ApprovalSchemeId: any
  ProjectId: any
  Position: any
}

export interface IProject {
  Id: number
  Name: string
  ResponsibleEmployeeId: number
}

export interface IDocuments {
  DefaultDocumentId: number
  AllowedDocumentTypes: string[]
}

export interface IErrors {
  selectedForeignPassportFoul: boolean
}

const getEmployeeFullName = ({ Surname = '', Name = '', Patronymic = '' }: Partial<IEmployeeFullNameObj>): string =>
  (Surname ? `${Surname} ${Name || ''} ${Patronymic || ''}`.trim() : '');

const containsFF = (featureFlags: string[], flagName: string) => {
  if (!featureFlags || !flagName) return false;

  return featureFlags.includes(flagName);
};

const getEmployeeFullNameWithSimplicity = (
  {
    Surname,
    Name = '',
    Patronymic = '',
  }: IEmployeeFullNameObj,
  dots = false,
) => {
  if (dots) {
    const name = [Surname, ' '];

    if (Name) {
      name.push(`${Name[0]}.`);
    }

    if (Patronymic) {
      name.push(`${Patronymic[0]}.`);
    }

    return name.join('');
  }

  return `${Surname} ${Name ? Name[0] : ''} ${Patronymic ? Patronymic[0] : ''}`.trim();
};

const employeeFullNameWithComas = (employees: IEmployeesItem[]) => employees
  .map(({ Name, Surname, Patronymic }) =>
    getEmployeeFullNameWithSimplicity({ Name, Surname, Patronymic }, true))
  .join(', ');

const getEmployeeFullNameWithFeatureFlags = (
  employee: ICommonEmployees,
  displayFF: string[] = [],
  showBirthday = false,
) => {
  const { Birthday, Documents } = employee;
  const ShortRuName = getEmployeeFullNameWithSimplicity(employee, true);
  const employeeDisplay = [];

  if (containsFF(displayFF, DisplayFF.FULLNAME)) {
    const fullName = getEmployeeFullName(employee);
    employeeDisplay.push(fullName);
  } else {
    employeeDisplay.push(ShortRuName);
  }

  if (showBirthday) {
    const dateOfBirth = formatDate(Birthday, defaultPattern);
    employeeDisplay.push(`${LABELS.DATE_BIRTHDAY} ${dateOfBirth}`);
  }

  if (containsFF(displayFF, DisplayFF.DOCUMENTS) && Documents && Documents.length) {
    employeeDisplay.push(`${LABELS.DOCUMENT}  ${Documents[0].Number}`);
  }

  return employeeDisplay;
};

const checkUnderageEmployees = (employees: IUnderageEmployeesItem[]) =>
  employees.some(({ Employee: { Underage } }) => Underage);

const checkAirUnderageEmployees = ({ Employees, Data }: IPrepareSourcesItem) => { // TODO IPrepareSourcesItem посмотреь в swagger
  const AGE_OF_NOT_BABY_ANYMORE = 2;
  const AGE_OF_NOT_CHILD_ANYMORE = 12;

  const { ProviderName, Routes } = parseJsonString(Data);

  if (ProviderName === AIRLINE_PROVIDERS.POBEDA) {
    return false;
  }

  const departureDate = Routes[0].Segments[0].DepartureTime_DateTime;
  const hasUnadultEmployee = Employees.some(({ Employee: { Birthday } }) => {
    const diff = diffYears(Birthday, departureDate);

    return diff >= AGE_OF_NOT_BABY_ANYMORE && diff < AGE_OF_NOT_CHILD_ANYMORE;
  });

  return hasUnadultEmployee;
};

// TODO принимает IApprovers, но там нет Id
const mapEmployeesListWithNamesakes = (list: any[] = []) => list.map((i) => {
  let namesake = NAMESAKES.NotDefined;

  list.forEach(({ Id: lId, Surname: lSurname, Name, Patronymic, Birthday }) => {
    const iPatronymic = i.Patronymic || '';
    const lPatronymic = Patronymic || '';
    const iName = i.Name || '';
    const lName = Name || '';
    const Documents = i.Documents || [];

    if (i.Id !== lId && (compareLowerStrings(i.Surname, lSurname)
        && compareLowerStrings(iName, lName)
        && compareLowerStrings(iPatronymic, lPatronymic))
    ) {
      if (i.Birthday !== Birthday) {
        namesake = NAMESAKES.Birthday;
      } else if (Documents.length !== 0) {
        namesake = NAMESAKES.Passport;
      } else {
        namesake = NAMESAKES.Email;
      }
    }
  });

  return {
    ...i,
    namesake,
  };
});

const employeeMayHavePassport = (date: MomentInput) => !!date && getMoment().diff(date, 'years') >= 14;

const checkDoubleDoc = (passport: any, documentType: any, previousDocuments: IDocumentUser[], value: any) => {
  const previousDocumentDouble = previousDocuments
    .some(({ Type, Number }) => Type === documentType.DomesticPassport && Number !== value);

  return passport.Id === 0 || previousDocumentDouble;
};

const isSameNames = (document: any, employee: any) => {
  const { Name, Surname, Patronymic } = document;
  const { Name: docName, Surname: docSurname, Patronymic: docPatronymic } = employee;

  return Name === docName && Surname === docSurname && Patronymic === docPatronymic;
};

const getApprovalSchemeIdForEmployee = (users: any[], employeeId: number | string | undefined) => {
  const user = users.find(({ value }) => value === employeeId);

  return user ? user.approvalSchemeId : null;
};

const employeesListMapper = (payload: any[]) => {
  const employees = new Set(payload.reduce((acc, request) => [...acc, ...request.EmployeesNames], []));

  return [...employees].map(employee => ({ value: employee, label: employee }));
};

const getApprovalERSchemeIdForEmployee = (users: any[], employeeId?: number | string | null) => {
  const user = users.find(({ value }) => value === employeeId);

  return user ? user.approvalExpenseReportSchemeId : null;
};

const checkTwoForeignPassports = (citizenship: string) =>
  (citizenship === 'UA' || citizenship === 'UZ' || citizenship === 'KG');

const employeeFullNameFromCart = (sources: IPrepareSourcesItem[]) =>
  sources.map((item: ISourcesItem) => item.Employees.map(({ Employee: { Surname, Name, Patronymic } }) =>
    `${Surname} ${Name} ${Patronymic}`)
    .join(', '))
    .join(', ');

export {
  DisplayFF,
  containsFF,
  getEmployeeFullNameWithSimplicity,
  employeeFullNameWithComas,
  getEmployeeFullNameWithFeatureFlags,
  getEmployeeFullName,
  mapEmployeesListWithNamesakes,
  checkUnderageEmployees,
  employeeMayHavePassport,
  isSameNames,
  checkAirUnderageEmployees,
  checkDoubleDoc,
  getApprovalSchemeIdForEmployee,
  employeesListMapper,
  getApprovalERSchemeIdForEmployee,
  checkTwoForeignPassports,
  employeeFullNameFromCart,
};
