import React, { Component } from 'react';
import { Button, IconButton, Text } from 'new-ui';

import { getText } from '../../../i18n';

import ProjectSelect from '../EmployeeList/ProjectSelect';

import { IProjects } from '../../bi/types/employees';

import styles from './styles.module.css';

const LABEL = {
  PROJECT: getText('components:projectsInEmployeePage.project'),
  SHOW: getText('components:projectsInEmployeePage.show'),
};

const finderProjectName = (projects: IProjects[], projectId?: number) => {
  const finderProject = projectId ? projects?.find(({ Id }) => Id === projectId) : null;

  return finderProject?.Name || '';
};

interface ProjectsInEmployeePageProps {
  projects: IProjects[],
  projectId?: number,
  isUserEmployee: boolean,
  onSelect(id: number): void,
  qaAttr?: string,
  qaAttrSearch?: string,
  qaAttrFirstEl?: string,
}

interface ProjectsInEmployeePageState {
  isShowSelect: boolean,
  projectName: string,
}

class ProjectsInEmployeePage extends Component<ProjectsInEmployeePageProps, ProjectsInEmployeePageState> {
  static defaultProps = {
    projectId: null,
    qaAttr: '',
    qaAttrSearch: '',
    qaAttrFirstEl: '',
  };

  constructor(props: ProjectsInEmployeePageProps) {
    super(props);

    const { projects, projectId } = props;

    this.state = {
      isShowSelect: false,
      projectName: finderProjectName(projects, projectId),
    };
  }

  toggleShowSelect = (isShowSelect: boolean) => this.setState({
    isShowSelect,
  });

  handleSelectProjectName = (id: number) => this.setState({
    projectName: finderProjectName(this.props.projects, id),
  });

  handleSelectSuggestion = (id: number) => {
    this.props.onSelect(id);
    this.handleSelectProjectName(id);
    this.toggleShowSelect(false);
  };

  renderPlaceholder = () => <Text color='gray'>{ LABEL.PROJECT }</Text>;

  renderProjectName = () => {
    const { qaAttr } = this.props;
    const { projectName } = this.state;

    if (projectName) {
      const deleteProjectButton = !this.props.isUserEmployee && (
        <IconButton
          iconType='closeButton'
          onClick={ () => this.handleSelectSuggestion(0) }
          className={ styles.remove }
        />
      );

      return (
        <div className={ styles.content }>
          <Text className={ styles.project_name }>{ projectName }</Text>
          { deleteProjectButton }
        </div>
      );
    }

    return (
      <div className={ styles.costCenters }>
        <Button
          tabIndex={ -1 }
          type='textual'
          onClick={ () => this.toggleShowSelect(true) }
          qaAttr={ qaAttr }
        >
          &nbsp;
          { LABEL.SHOW }
        </Button>
      </div>
    );
  };

  render() {
    const { qaAttrSearch, qaAttrFirstEl } = this.props;

    if (this.state.isShowSelect) {
      return (
        <ProjectSelect
          projects={ this.props.projects }
          onSelect={ this.handleSelectSuggestion }
          onClose={ () => this.toggleShowSelect(false) }
          qaAttr={ qaAttrSearch }
          qaAttrFirstEl={ qaAttrFirstEl }
        />
      );
    }

    return (
      <div className={ styles.project }>
        { this.renderPlaceholder() } { this.renderProjectName() }
      </div>
    );
  }
}

export default ProjectsInEmployeePage;
