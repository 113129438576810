import { Moment } from 'moment';
import { OrderEmployees } from './employees';

type ServiceTypes =
  'Air' |
  'Hotel' |
  'Transfer' |
  'Train' |
  'AeroExpress' |
  'TaxiVoucher' |
  'FlightCertificate' |
  'Custom' |
  'VipHall' |
  'AirAdditionalService';

type TripStatuses =
  'Booked' |
  'Cancelled' |
  'AwaitingConfirmation' |
  'AwaitingTicketing' |
  'Completed';

export enum EnumTripInsuredType {
  NotInsured = 'notInsurance',
  Insured = 'insured',
  PartiallyInsured = 'partlyInsured',
}

interface Tag {
  Id: number;
  Value: string;
}

interface Project {
  Id: number;
  Name: string;
}

interface Employee {
  EmployeeId: number;
  FullName: string;
}

interface UserAnalytic {
  Id: number;
  Name: string;
  Values: UserAnalyticValue[];
}

interface UserAnalyticValue {
  Id: number;
  Name: string;
}

interface Department {
  CompanyId: number;
  CompanyName: string;
  Departments: IDepartmentItem[];
  ShortCompanyName: string;
}

interface IDepartmentItem {
  Id: number;
  Name: string;
  CompanyId: number,
  Budget: null | any,
  HeadOfDepartmentId: number,
  EmployeesIds: number[],
}

interface TripsFilters {
  Cities: string[];
  Companies: number[];
  Departments: number[];
  Statuses: string[];
  Employees: number[];
  Services: string[];
  Tags: number[];
  Projects: number[];
  StartDate: Moment | null;
  EndDate: Moment | null;
  AnalyticValues: number[];
  UserIsOwner: boolean;
}

interface TripsReport extends Omit<TripsFilters, 'StartDate' | 'EndDate'> {
  StartDate: string,
  EndDate: string,
}

interface TripsReq extends TripsFilters {
  Limit: number;
  Page: number;
}

interface TripData {
  ChangeDate: Moment;
  CheckInDate: Moment;
  CheckOutDate: Moment;
  EmployeesFull: Employee[];
  Id: number;
  Name: string;
  Price: number;
  Services: ServiceTypes[];
  Status: TripStatuses;
  AdditionalFee: number;
  MICEEventId: string | null,
  Labels: string[],
  InsuranceStatus: 'Insured' | 'NotInsured' | 'PartiallyInsured' | ''
}

interface TripsRes {
  PageCount: number;
  TotalPrice: number;
  Trips: TripData[];
}

interface FiltersRes {
  Cities: string[];
  Companies: number[];
  Departments: number[];
  Employees: Employee[];
  Services: ServiceTypes[];
  Statuses: TripStatuses[];
  Tags: Tag[];
  UserAnalytics: UserAnalytic[];
  Projects: Project[];
  ShowButtonOwner: boolean;
  ShowButtonEvent: boolean;
}

interface ITripItemActualVersion {
  AdditionalData: { PropertyFees: string[] },
  AllowedDocumentTypes: string[],
  CheckinDate: string,
  CheckoutDate: string,
  City: string,
  CompanyId: number,
  CreatedBy: string,
  DepartmentId: number,
  Description: string,
  Employees: OrderEmployees[],
  EventDate: string,
  Id: number,
  JsonData: string,
  OperationDetailsId: number,
  Penalties: any | null,
  Price: number,
  ProjectId: any | null,
  ProviderName: string,
  ReturnFunds: any | null,
  Surcharges: any | null,
  VatDetails: any | null,
  ServiceSourceType: string,
  ServiceType: string,
  Status: number,
  TripItemId: number,
  Vat: number,
  StatusExplanation: string,
}

interface ITripItem {
  ActualVersion: ITripItemActualVersion,
  AgentFee: number,
  AirlineVatSchema: number,
  ApprovedStatus: [],
  Id: number,
  IsAdditionalItem: null | any,
  RequestId: null | any,
  SupplierDocument: null | any,
  Tags: Tag[],
  UserAnalytics: UserAnalytic[],
  Versions: ITripItemActualVersion[],
}

interface IAdditionalService {
  TicketNumber: string;
  newService: boolean;
  text: string;
  tripInfo: string;
}

export enum AnalyticTripFilter {
  city = 'city',
  employees = 'employees',
  services = 'services',
  status = 'status',
  date = 'date',
  organization = 'organization',
  cost_center = 'cost_center',
  analysts = 'analysts',
  tags = 'tags',
}

export type {
  TripsReq,
  TripsFilters,
  ServiceTypes,
  Employee,
  TripStatuses,
  Tag,
  TripsRes,
  FiltersRes,
  TripData,
  Project,
  UserAnalytic,
  UserAnalyticValue,
  Department,
  TripsReport,
  IAdditionalService,
  ITripItem,
  ITripItemActualVersion,
};
