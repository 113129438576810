import React, { memo } from 'react';
import { Text, TextColor, Tooltip } from 'new-ui';

import { getText } from '../../../i18n';

import { TechnicalStopS7Text, TechnicalStopText } from './components';

import toDecline from '../../bi/utils/toDecline';

import { ITechnicalStop } from '../../bi/types/airline';

import styles from './style.module.css';

const LABELS = {
  withoutTransfers: getText('components:airlineSearchItem.airItem.withoutTransfers'),
  technicalStopDeclines: ['техническая посадка', 'технические посадки'],
};

interface ITechnicalStopTooltipProps {
  changeCount: number;
  technicalStop: ITechnicalStop[] | string[];
  className?: string;
}

const renderTooltipTechnicalStop = (technicalStopInfo: ITechnicalStop[] | string[], textColor?: TextColor) => {
  if ((technicalStopInfo as ITechnicalStop[])[0]?.Details) {
    return (
      <div className={ styles.tooltip }>
        <TechnicalStopText
          className={ styles.text }
          technicalStopInfo={ technicalStopInfo as ITechnicalStop[] }
          textColor={ textColor }
        />
      </div>
    );
  }

  return (
    <div className={ styles.tooltip }>
      <TechnicalStopS7Text
        className={ styles.text }
        technicalStopInfo={ technicalStopInfo as string[] }
        textColor={ textColor }
      />
    </div>
  );
};

export const AirlineTechnicalStop = memo(({
  changeCount,
  technicalStop,
  className = '',
}: ITechnicalStopTooltipProps) => {
  const mayHaveRenderTechnicalStops = technicalStop.length && !changeCount;

  const handleRenderContent = () => renderTooltipTechnicalStop(technicalStop, 'white');

  if (!mayHaveRenderTechnicalStops) return null;

  const titleClassNames = [styles.title];

  if (className) {
    titleClassNames.push(className);
  }

  const rendertechnicalStopDeclines = () => {
    const technicalStopsCountInfo = technicalStop.length;

    if (!technicalStopsCountInfo) return null;

    return `${technicalStopsCountInfo} ${toDecline(technicalStopsCountInfo, LABELS.technicalStopDeclines)}`;
  };

  return (
    <div className={ styles.technical_stop_wrapper }>
      <Text type='NORMAL_14' color='gray'>{ LABELS.withoutTransfers }</Text>
      <Tooltip
        className={ styles.technical_stop }
        renderContent={ handleRenderContent }
        position='bottom'
      >
        <Text
          className={ titleClassNames.join(' ') }
          type='NORMAL_14'
          color='gray'
        >
          { rendertechnicalStopDeclines() }
        </Text>
      </Tooltip>
    </div>
  );
});
