import { Text, Price } from 'new-ui';
import React from 'react';

import { getText } from '../../../../i18n';

import { AirPrice } from '../../../bi/types/airline';

import styles from '../styles/tooltipText.module.css';

const LABELS = {
  BASE: getText('components:airlineSearchItem.tooltipText.base'),
  TAX: getText('components:airlineSearchItem.tooltipText.tax'),
  FEE: getText('components:airlineSearchItem.tooltipText.fee'),
  COMMISSION: getText('components:airlineSearchItem.tooltipText.commission'),
  TOTAL_PRICE: getText('components:airlineSearchItem.tooltipText.totalPrice'),
  AVERAGE_COST: getText('components:airlineSearchItem.tooltipText.averageCost'),
};

const normalText = (text: string | number) => (
  <Text type={ 'NORMAL_14_130' } color={ 'white' }>{ text }</Text>
);

const semiBoldText = (text: string | number) => (
  <Price
    marginSmall
    type={ 'SEMIBOLD_14' }
    typeCurrency={ 'SEMIBOLD_14' }
    color={ 'white' }
    value={ text }
  />
);

interface IToolTipTextProps {
  price: AirPrice,
  isDetails?: boolean,
  travellers?: number,
}

const TooltipText = ({ price: {
  Base,
  Tax,
  Fee,
  Commission,
  TotalPrice,
}, isDetails = false } : IToolTipTextProps) => {
  const renderTooltipTextOptions = () => (
    <div className={ styles.wrapper }>
      <div className={ styles.row }>
        {normalText(LABELS.BASE)}&ensp;
        {semiBoldText(Base)}
      </div>
      <div className={ styles.row }>
        {normalText(LABELS.TAX)}&ensp;
        {semiBoldText(Tax)}
      </div>
      <div className={ styles.row }>
        {normalText(LABELS.FEE)}&ensp;
        {semiBoldText(Fee)}
      </div>
      <div className={ styles.row }>
        {normalText(LABELS.COMMISSION)}&ensp;
        {semiBoldText(Commission)}
      </div>
      <div className={ styles.row }>
        {normalText(LABELS.TOTAL_PRICE)}&ensp;
        {semiBoldText(TotalPrice)}
      </div>
    </div>
  );

  const renderTooltipTextPrice = () => (
    <Text
      type={ 'NORMAL_14_130' }
      color={ 'white' }
      className={ styles.wrapper_options }
    >
      { LABELS.AVERAGE_COST }
    </Text>
  );

  return isDetails ? renderTooltipTextOptions() : renderTooltipTextPrice();
};

export default TooltipText;
