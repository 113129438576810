import React, { Component } from 'react';
import { Moment } from 'moment';
import { Text, Button, Dialog, IconButton, Price } from 'new-ui';
import { getText } from '../../../i18n';

import { EditableText } from '../EditableText';

import EventService from '../../bi/services/events';

import { formatRangeDateWithSimplicity } from '../../bi/utils/formatDate';
import { svgColor } from '../../bi/utils/env';
import { MainAnalytic } from '../../bi/utils/analytics';

import styles from './styles/index.module.css';

interface ITripNameProps {
  name: string,
  price?: number,
  isCheckout?: boolean,
  minDate: Moment,
  maxDate: Moment,
  onChange: (value: string) => void,
  eventService: EventService,
  onClick: () => void,
  qaAttrName: string,
  qaAttrInput: string,
  qaAttrSave: string,
  qaAttrClose: string,
  qaAttrDeleteAll: string,
  qaAttrModalWrapper: string,
  qaAttrModalSuccess: string,
  qaAttrModalCancel: string,
  qaAttrModalClose: string,
  qaAttrPrice?: string,
  hasBaggageOffers: boolean,
  airlineBaggageServiceDeleteOffers: () => void,
}

interface ITripNameState {
  showDialog: boolean,
}

const LABELS = {
  REMOVE_ALL: getText('components:tripName.removeAll'),
  DIALOG_TITLE: getText('components:tripName.dialog.title'),
  DIALOG_DESCRIPTION: getText('components:tripName.dialog.description'),
  OK: getText('common:ok'),
  CANCEL: getText('common:cancel'),
};

class TripName extends Component<ITripNameProps, ITripNameState> {
  state = {
    showDialog: false,
  };

  setDialog = (value: boolean) => this.setState({ showDialog: value });

  handleDelete = () => {
    const {
      onClick,
      isCheckout,
      eventService: {
        setOpenSwitcher,
        setValueSwitcher,
      },
      hasBaggageOffers,
      airlineBaggageServiceDeleteOffers,
    } = this.props;

    onClick();
    this.setDialog(false);

    setOpenSwitcher(false);
    setValueSwitcher('');

    if (!isCheckout) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.CART_DELETE_EVERYTHING);
    }

    if (hasBaggageOffers) {
      airlineBaggageServiceDeleteOffers();
    }
  };

  handleChange = (value: string) => {
    this.props.onChange(value);

    if (!this.props.isCheckout) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.CART_CHANGED_NAME);
    }
  };

  render() {
    const {
      name,
      price,
      isCheckout,
      minDate,
      maxDate,
      qaAttrName,
      qaAttrInput,
      qaAttrSave,
      qaAttrClose,
      qaAttrDeleteAll,
      qaAttrModalWrapper,
      qaAttrModalSuccess,
      qaAttrModalCancel,
      qaAttrModalClose,
      qaAttrPrice,
    } = this.props;

    const { showDialog } = this.state;

    const datesString = formatRangeDateWithSimplicity(minDate?.isValid() ? minDate : maxDate, maxDate);
    const datesContent = datesString && (
      <Text className={ styles.dates } type='NORMAL_14' color='gray'>
        { datesString }
      </Text>
    );

    const rightHtml = isCheckout && price ? (
      <div className={ styles.price }>
        <Price qaAttr={ qaAttrPrice } value={ Number(price) }/>
      </div>
    ) : (
      <Button
        qaAttr={ qaAttrDeleteAll }
        type='textual'
        onClick={ () => this.setDialog(true) }
      >
        { LABELS.REMOVE_ALL }
      </Button>
    );

    const wrapperClassNames = [styles.wrapper];

    if (isCheckout) {
      wrapperClassNames.push(styles.checkout);
    }

    return (
      <div className={ wrapperClassNames.join(' ') }>
        <div className={ styles.left }>
          <EditableText
            renderPreview={ () => (
              <IconButton
                iconType='menuTrips'
                iconColor={ svgColor }
                className={ styles['preview-icon'] }
              >
                <Text type={ isCheckout ? 'bold_24' : 'NORMAL_16' } className={ styles['preview-text'] }>{ name }</Text>
              </IconButton>
            ) }
            onChange={ this.handleChange }
            value={ name }
            qaAttrInput={ qaAttrInput }
            qaAttrSave={ qaAttrSave }
            qaAttrClose={ qaAttrClose }
            qaAttrWrapper={ qaAttrName }
          />
          { datesContent }
        </div>
        <div className={ styles.right }>
          { rightHtml }
        </div>
        <Dialog
          show={ showDialog }
          onChange={ this.setDialog }
          showClosing
          qaAttrWrapper={ qaAttrModalWrapper }
          qaAttr={ qaAttrModalClose }
        >
          <div className={ styles['dialog-container'] }>
            <Text type='bold_20'>{ LABELS.DIALOG_TITLE }</Text>
            <Text className={ styles.text } type='NORMAL_14_130'>{ LABELS.DIALOG_DESCRIPTION }</Text>
            <div className={ styles.actions }>
              <Button
                type='primary'
                onClick={ this.handleDelete }
                qaAttr={ qaAttrModalSuccess }
              >
                { LABELS.OK }
              </Button>
              <Button
                className={ styles.cancel }
                type='textual-medium'
                onClick={ () => this.setDialog(false) }
                qaAttr={ qaAttrModalCancel }
              >
                { LABELS.CANCEL }
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default TripName;
