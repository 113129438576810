import React from 'react';
import { Text, Tooltip, Price } from 'new-ui';

import { getText } from '../../../../i18n';

import Config from '../../../../config';

import TooltipText from './tooltipText';

import toDecline from '../../../bi/utils/toDecline';

import { TICKETS } from '../../../bi/constants/airline';

import { AirPrice } from '../../../bi/types/airline';

import styles from '../styles/travellersAmount.module.css';

const LABELS = {
  by: getText('components:airlineSearchItem.travellersAmount.by'),
};

type ITravellersAmountProps = {
  travellers: number;
  currentFareId: string;
  fares: { Id: string; Price: AirPrice }[];
  qaAttr: string;
};

const TravellersAmount = ({ travellers = 0, currentFareId = '', fares = [], qaAttr = '' } : ITravellersAmountProps) => {
  const price = fares.find(({ Id }) => currentFareId === Id)?.Price;

  if (!price) return null;

  const pricePerPerson = price?.TotalPrice / travellers;

  const tooltip = () => (
    <TooltipText
      price={ price }
      travellers={ travellers }
    />
  );

  return (
    <Tooltip
      position={ 'left' }
      show={ Config.SHOW_PRICE_DETAILS }
      renderContent={ tooltip }
      className={ styles.wrapper }
    >
      <div className={ styles.wrap_price }>
        <Text
          type={ 'NORMAL_12' }
          color={ 'gray' }
          className={ styles.text }
          qaAttr={ qaAttr }
        >
          { travellers } { toDecline(travellers, TICKETS) } { LABELS.by }
        </Text>
        <Price
          marginSmall
          type={ 'NORMAL_12' }
          typeCurrency={ 'NORMAL_12' }
          color={ 'gray' }
          value={ pricePerPerson }
          className={ styles.price }
        />
      </div>
    </Tooltip>
  );
};

export { TravellersAmount };
