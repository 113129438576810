import React from 'react';
import { Button, Dialog, Text } from 'new-ui';

import { getText } from '../../../i18n';

import { BOOKINGSTEP, CHANGES } from '../../bi/types/booking';

import styles from '../../page/Booking/styles/index.module.css';

const LABELS = {
  TICKETSNOTAVAILABLE: getText('booking:ticketsNotAvailable'),
  BACKTOCART: getText('booking:backToCart'),
  COMMON_ERROR_DIALOG: {
    TITLE: getText('booking:commonErrorDialog.title'),
    TO_TP_HELPER: getText('booking:commonErrorDialog.toTpHelper'),
  },
  BOOKING_ERROR_DIALOG: {
    TITLE: getText('booking:bookingErrorDialog.title'),
  },
};

interface IBookingErrorDialogProps {
  errorMessages: string[],
  errors: { CartItem: React.ReactNode, Message: string }[],
  isCancelling: boolean,
  showErrorDialog: boolean,
  statuses: any[],
  renderCartItem: (CartItem: React.ReactNode) => React.ReactNode,
  onClickErrorDialog(): void,
  onSendRequest(): void,
}

const BookingErrorDialog = ({
  errorMessages,
  errors,
  isCancelling,
  showErrorDialog,
  statuses,
  onClickErrorDialog,
  renderCartItem,
  onSendRequest,
}: IBookingErrorDialogProps) => {
  if (isCancelling) {
    return null;
  }

  const renderCommonErrorDialog = () => {
    const errorMessagesHtml = () => errorMessages.map((message, index) =>
      <div key={ index } className={ styles.error }>
        <Text className={ styles.message } type={ 'NORMAL_14' } color={ 'red' }>
          { message }
        </Text>
      </div>,
    );

    const errorsHtml = () => errors.map(({ CartItem, Message }, index) =>
      <div key={ index } className={ styles.error }>
        { CartItem && (renderCartItem(CartItem)) }
        <Text className={ styles.message } type={ 'NORMAL_14' } color={ 'red' }>
          { Message }
        </Text>
      </div>,
    );

    const renderErorrs = () => (errorMessages.length ? errorMessagesHtml() : errorsHtml());

    return (
      <Dialog
        show={ showErrorDialog }
        onChange={ () => {} }
      >
        <div className={ styles.dialog }>
          <Text type={ 'bold_20' } className={ styles.title }>
            {LABELS.COMMON_ERROR_DIALOG.TITLE}
          </Text>
          { renderErorrs() }
          <div className={ styles.actions }>
            <Button onClick={ onClickErrorDialog } type={ 'primary' }>
              { LABELS.BACKTOCART }
            </Button>
            <Button onClick={ onSendRequest } type={ 'textual' } className={ styles.secondary }>
              {LABELS.COMMON_ERROR_DIALOG.TO_TP_HELPER}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const renderBookingErrorDialog = () => {
    const withError = statuses.filter(
      ({ BookingStep, Changes }) =>
        BookingStep === BOOKINGSTEP.BookingError ||
        BookingStep === BOOKINGSTEP.ReservationError ||
        Changes === CHANGES.NotEnoughPlaces,
    );

    return (
      <Dialog
        show={ showErrorDialog }
        onChange={ onClickErrorDialog }
      >
        <div className={ styles.dialog }>
          <Text type={ 'bold_20' } className={ styles.title }>{LABELS.BOOKING_ERROR_DIALOG.TITLE}</Text>
          {withError.map(({ Error }, id) => (
            <Text type={ 'NORMAL_14_130' } key={ id }>
              { Error || LABELS.TICKETSNOTAVAILABLE }
            </Text>
          ))}
          <div className={ styles.actions }>
            <Button
              type={ 'primary' }
              onClick={ onClickErrorDialog }
              loading={ isCancelling }
            >
              { LABELS.BACKTOCART }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  return errorMessages?.length || errors?.length > 0
    ? renderCommonErrorDialog()
    : renderBookingErrorDialog();
};

export { BookingErrorDialog };
