import React, { ReactNode } from 'react';
import { Text, ItemPanel } from 'new-ui';
import { getText } from '../../../../../i18n';

import { formatDate } from '../../../../bi/utils/formatDate';
import { getAirlineType } from '../../../../bi/utils/airline';

import { ADDITIONAL_SERVICES_CONSTS } from '../../../../bi/constants/additionalServices';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { PATTERN } from '../../../../bi/constants/dateFormats';
import { AIRLINE_PROVIDERS } from '../../../../bi/constants/airline';

import ItemLayout from '../../../ItemLayout';

import { TripItemEmployees } from '../TripItemEmployees';

import styles from './index.module.css';

interface IAdditionalServicesItemsProps {
  item: {
    ActualVersion: {
      Employees: {
        Id: number,
      }[],
      ProviderName: string,
    }
  },
  itemData: {
    AdditionalServices: any[],
    TicketsExtended: any[],
    Routes: any[],
  },
  companies: any[],
  projects: any[],
  renderCustomAnalyticsAndTags: (arg: boolean) => ReactNode,
}

const LABELS = {
  TICKET: getText('components:tripItem.paidSeats.ticket'),
};

const ADDITIONAL_SERVICES_LABELS: { [key: string]: string } = {
  Number: getText('components:tripItem.additionalServices.number'),
  Mass: getText('components:tripItem.additionalServices.mass'),
  Description: getText('components:tripItem.additionalServices.description'),
  emd: getText('components:tripItem.additionalServices.emd'),
  supplier: getText('components:tripItem.additionalServices.supplier'),
  price: getText('components:tripItem.additionalServices.price'),
  commission: getText('components:tripItem.additionalServices.commission'),
  vat: getText('components:tripItem.additionalServices.vat'),
};

const seatType = 0;

const AdditionalServicesItems = ({
  item: {
    ActualVersion,
    ActualVersion: { Employees },
  },
  itemData: { AdditionalServices = [], TicketsExtended = [], Routes = [] },
  companies,
  projects,
  renderCustomAnalyticsAndTags,
}: IAdditionalServicesItemsProps) => AdditionalServices.reduce((arr, { Type, Service, SegmentId, TravellerId }, index) => {
  if (Service.Price > 0) {
    const currentVersionWithOneEmployee = {
      ...ActualVersion,
      Employees: Employees.filter(({ Id: EmployeeId }) => EmployeeId === TravellerId),
    };

    const currentSegment = Routes.flatMap(({ Segments }) => Segments).find(({ ID }) => ID === SegmentId);
    const airlineType = getAirlineType(currentSegment);

    const ticketNumber = TicketsExtended.length && TicketsExtended[0].Num ? `${LABELS.TICKET} #${TicketsExtended[0].Num}, ` : '';

    const { Code: airlineId } = currentSegment[airlineType];
    const price = Service.Price + (Service.Comission || 0);

    const {
      FlightNumber,
      DepartureAirport: { Code: DepartureCityCode },
      ArrivalAirport: { Code: ArrivalCityCode },
      DepartureCity,
      ArrivalCity,
      DepartureDate,
    } = currentSegment;

    const isPobeda = ActualVersion.ProviderName === AIRLINE_PROVIDERS.POBEDA && Type === seatType;

    if (isPobeda) {
      return [...arr];
    }

    const departureDate = formatDate(DepartureDate, PATTERN.DATE_WITH_YEAR);

    const html = (
      <div key={ `${SegmentId}_${Service.Type}_${index}` } className={ styles.wrapper }>
        <ItemPanel renderHeader={ () => null } className={ styles['additional-service'] }>
          <div className={ styles.body }>
            <ItemLayout isSeat AdditionalServiceType={ Type } price={ price } serviceType={ SERVICETYPE.AIR }>
              <Text type='bold_18'>{ADDITIONAL_SERVICES_CONSTS[Type].name}</Text>
              <Text className={ styles.seat_info } type='NORMAL_14_130'>
                {ticketNumber}
                {airlineId} {FlightNumber}, {DepartureCity} ({DepartureCityCode}) – {ArrivalCity} ({ArrivalCityCode}), {departureDate}
              </Text>
              {ADDITIONAL_SERVICES_CONSTS[Type].fields.map(field => (
                <Text
                  key={ `${SegmentId}_${Service.Type}_${index}_${ADDITIONAL_SERVICES_CONSTS[Type].name} ${Service[field]}` }
                  className={ styles.seat_info }
                  type='NORMAL_14_130'
                >
                  { ADDITIONAL_SERVICES_LABELS[field] }: { Service[field] }
                </Text>
              ))}
            </ItemLayout>
            <div className={ styles.actions }>
              <div className={ styles.info }>
                <div className={ styles.employees }>
                  <TripItemEmployees
                    currentVersion={ currentVersionWithOneEmployee }
                    companies={ companies }
                    projects={ projects }
                  />
                </div>
              </div>
              { renderCustomAnalyticsAndTags(true) }
            </div>
          </div>
        </ItemPanel>
      </div>
    );

    return [...arr, html];
  }

  return arr;
}, []);

export { AdditionalServicesItems };
