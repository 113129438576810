import { IconButton } from 'new-ui';
import React, { useState, useEffect, useCallback, useRef, CSSProperties, ReactNode } from 'react';
import * as ReactDOM from 'react-dom';

import { MAIN_MENU_WRAPPER } from '../../bi/constants/elementsIds';

import styles from './styles/index.module.css';

interface StickyHidingPanelProps {
  children: ReactNode;
  fixedClass?: string;
  className?: string;
  renderOnHeader?: boolean;
  initial?: number;
  adjustment: number;
  hiddenAdjustment: number;
  style?: CSSProperties;
  tags?: string[];
}

interface Rect {
  top: number;
  left: number;
  width: number;
  height: number;
}

const StickyHidingPanel = ({
  children,
  fixedClass,
  className,
  renderOnHeader,
  initial,
  adjustment,
  hiddenAdjustment,
  style: pStyle,
  tags,
}: StickyHidingPanelProps) => {
  const [isSticky, setSticky] = useState(false);
  const [isHidden, setHidden] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => setHidden(false), [tags]);

  const getRect = useCallback((): Rect => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();

      return rect as unknown as Rect;
    }

    return {} as Rect;
  }, [ref]);

  const handleScroll = useCallback((event: Event) => {
    const scrollY = (event.target as HTMLElement).scrollTop + adjustment;
    const pageHeight = document.documentElement.scrollHeight - hiddenAdjustment;

    if (scrollY > pageHeight) {
      return null;
    }

    if (getRect().top < scrollY) {
      setSticky(true);
      setHidden(true);
    }

    if (adjustment >= scrollY) {
      setSticky(false);
      setHidden(false);
    }

    return null;
  }, [adjustment, hiddenAdjustment, getRect]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const classNames = [];

  const style = {
    left: getRect().left,
    width: getRect().width,
    ...pStyle,
  };

  const handleHidden = () => {
    setHidden(!isHidden);
  };

  if (isSticky) {
    classNames.push(styles.fixed, fixedClass);
  }

  if (initial) {
    style.top = initial;
  }

  if (isSticky && renderOnHeader) {
    style.zIndex = 1;
  }

  if (isSticky) {
    style.top = adjustment;
  }

  if (!isSticky && className) {
    classNames.push(className);
  }

  if (isSticky) {
    const showBody = !isHidden && children;

    const stickyContent = (
      <div>
        <div style={ { height: `${getRect().height}px` } } />
        <div
          ref={ ref }
          className={ classNames.join(' ') }
          style={ style }
        >
          { showBody }
          <div
            className={ styles.sticky_footer }
            onClick={ () => handleHidden() }
          >
            <IconButton
              iconType='arrowsDown'
              className={ !isHidden ? styles.arrow_up : styles.arrow_down }
            />
          </div>
        </div>
      </div>
    );

    if (renderOnHeader) {
      const portalContainer = document.getElementById(MAIN_MENU_WRAPPER);

      if (portalContainer) {
        return ReactDOM.createPortal(stickyContent, portalContainer);
      }

      return null;
    }

    return stickyContent;
  }

  return (
    <div
      className={ classNames.join(' ') }
      style={ style }
      ref={ ref }
    >
      { children }
    </div>
  );
};

export { StickyHidingPanel };
